import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import { sub_user_id, add_user, update_user, user_password_reset_request } from '../../service/sub_user'
import { accommodation } from '../../service/accommodation'
import '../margin_add/style.css';
import dayjs from 'dayjs';

const UsersAdd = ({user_token, set_menu, authorize}) => {

    const [user_d, setUserData] = useState(null);

    const [emailAddress, setEmailAddress] = useState("");
    const [roleID, setRoleID] = useState(3);
    const [enabled, setEnabled] = useState(0);
    const [btn_lbl, setBtnLbl] = useState('Add');
    const [ready, setReady] = useState(false)


	useEffect(() =>{

		authorize(1);

        const lnks = [{'url': '/', 'name': 'Home'}, {'url': '/users', 'name': 'Users'}]
		set_menu(lnks)


		let queryStrings = queryString.parse(window.location.search)
		let uid = queryStrings.id;


        if(uid == null){
            setReady(true);
            return
        }

		let f = "id email_address username user_ref role_id enabled created"

		let x = sub_user_id(uid, f, user_token).then((r) =>{

			if(r == null){
				return
			}	

			if(r.errors != null){
					if(r.errors.length > 0){
						alert(r.errors[0].message);
						return
					}
					alert("Unexpected Error Contact HWT support...");
					return
			}

			if(r.client_subuser_id.error != null){
                alert(r.client_subuser_id.message)
				return
			}        
            
            if(r.client_subuser_id.result == null){
				return
			}

			setUserData(r.client_subuser_id.result);
            setEmailAddress(r.client_subuser_id.result.email_address);
            setRoleID(r.client_subuser_id.result.role_id);
            setEnabled(r.client_subuser_id.result.enabled);
            setBtnLbl('Update');    
            setReady(true);
		}
		);
    }, [])

    const enable = (chk, value) => {
        if(chk && value == 0){
            setEnabled(0);
        }
        else{
            setEnabled(1);
        }
    }


    const resetPassword = async () => {
        if(!window.confirm('Reset users password ?')){
            return
        }
        const d = await user_password_reset_request(emailAddress);

        if(d){
            alert("Password resert instructions sent to user");
        }
        else{
            alert("Failed to issue password reset. Contact HWT support")
        }
        return 
    }

    const addupdate_user = async () => {     
        
        
        let email_address = document.getElementById("txt_email_address").value;
        let role_id = document.getElementById("ddl_role").value;

        //make graph all and add this sucker

        if(user_d == null){
            let new_user = await add_user(email_address, role_id, "id", user_token);
            if(new_user == null){
                alert("Failed to add user. Contact HWT support");
                return
            }
        }
        else{
            let new_marg = await update_user(user_d.id, email_address, role_id, enabled, "id", user_token);
            if(new_marg == null){
                alert("Failed to update margin. Contact HWT support");
                return
            }

        }


        window.location.replace("/users");
    }


    if(!ready){
        return(<div className='input_form'>Loading form...</div>)
    }

    let scEmail = <div className='input_section'>
    <div className='input_label_section'>
        <label>Email Address</label>
    </div>
    <div className='input_field_section medium'>
        <input id='txt_email_address' onChange={(d) => setEmailAddress(d.value)} type='text' value={emailAddress}></input>
    </div>
    </div>

    let role_ops = [{"value": 1, "text": "Administrator"}, {"value": 2, "text": "User (Write)"}, {"value": 3, "text": "User (Read only)"}, {"value": 4, "text": "Marketing"}, {"value": 5, "text": "Stats"}]
    let role_ops_x = [];

    for(let x of role_ops){
        if(roleID == x.value){
            role_ops_x.push(<option selected={true} value={x.value}>{x.text}</option>);
        }
        else{
            role_ops_x.push(<option value={x.value}>{x.text}</option>);
        }
    }

    let scRole = <div className='input_section'>
    <div className='input_label_section'>
    <label>Role</label>
    </div>
    <div className='input_field_section'>
    <select id='ddl_role' onChange={(d) => setRoleID(d.value)}>
        {role_ops_x}
    </select>
    </div>
    </div>

    let noCheck = true;
    let yesCheck = false;
    if(enabled == 1){
        noCheck = false;
        yesCheck = true;
    }

    let scEnabled = <div className='input_section'>
    <div className='input_label_section'>
        <label>Enabled</label>
    </div>
    <div className='input_field_section'>
        <input type="radio" id="optEnabledNo" onChange={(d) => enable(d.target.checked, d.target.value)} checked={noCheck} name="optEnabled" value="0"></input>
        <label for="optEnabledNo">No</label>
        <input type="radio" id="optEnabledyes" onChange={(d) => enable(d.target.checked, d.target.value)} checked={yesCheck} name="optEnabled" value="1"></input>
        <label for="optEnabledyes">Yes</label>
    </div>
    </div>

    let btnResetPassword = <div className='btn_section'>
    <button onClick={() => {resetPassword()}}>Reset Password</button>
    </div>

    if(user_d == null){
        btnResetPassword = null;
        scEnabled = null;
    }

    let btn = <div className='btn_section'>
    <button onClick={() => {addupdate_user()}}>{btn_lbl}</button>
    </div>


    return(<div className='input_form'>
        {scEmail}
        {scRole}
        {scEnabled}
        {btnResetPassword}
        {btn}
    </div>);
};

export default UsersAdd;
