import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs'
import AccommodationCountBlock from '../../components/statblocks/acc/count_block'
import SearchCountBlock from '../../components/statblocks/search/count_block'
import JourneyCountBlock from '../../components/statblocks/journey/count_block'
import BookingCountBlock from '../../components/statblocks/bookings/count_block'
import BookingCostBlock from '../../components/statblocks/bookings/cost'
import BookingAvgCostBlock from '../../components/statblocks/bookings/avg_cost'

import SessionAvgCostBlock from '../../components/statblocks/session/avg_cost_block'
import SessionCountBlock from '../../components/statblocks/session/count_block'
import SessionTotalCostBlock from '../../components/statblocks/session/total_cost'

import AccTopLists from '../../components/lists/acc/top_views'
import DestTopList from '../../components/lists/destinations/top_views'
import RegionTopList from '../../components/lists/regions/top_views'
import ResortTopList from '../../components/lists/resorts/top_views'
import RouteTopList from '../../components/lists/routes/top_views'

import SearchDepMonthTable from '../../components/tables/search/dep_date'

import AccommodationHits from '../../components/charts/acc/acc_hits'
import JourneyProgress from '../../components/charts/journey/progress'

import YMDPicker from '../../components/ymd_picker'

import { getCookie }  from '../../utils/shared'

const Home = ({user_token, set_menu, authorize}) => {


	let dFrom = dayjs();
	dFrom = dFrom.subtract(30, 'minutes');
	dFrom = dFrom.format('YYYY-MM-DD HH:mm')

	let dTo = dayjs();
	dTo = dTo.format('YYYY-MM-DD HH:mm')

	const [selected_from_date, setSelectedFromDate] = useState(dFrom)
	const [selected_to_date, setSelectedToDate] = useState(dTo)

	let dFromMid = dayjs();
	dFromMid = dFromMid.format('YYYY-MM-DD 00:00')

	let dToMid = dayjs();
	dToMid = dToMid.format('YYYY-MM-DD 23:59')

	const [selected_from_date_mid, setSelectedFromDateMid] = useState(dFromMid)
	const [selected_to_date_mid, setSelectedToDateMid] = useState(dToMid)

	const [custom_from, setCustomFrom] = useState(null)
	const [custom_to, setCustomTo] = useState(null)



	const [midType, setMidType] = useState(1)

	const [prodType, setProdType] = useState(null)

	useEffect(() =>{


    }, [])
	
	const updateProductType = (value) => {

		if(value == ""){
			setProdType(null);
		}
		else{
			setProdType(Number(value));
		}
	}

	const updateMidRange = (value) => {

		if(value == 0){
			// 30 mins
			let dFrom = dayjs();
			dFrom = dFrom.subtract(30, 'minutes');
			dFrom = dFrom.format('YYYY-MM-DD HH:mm')

			let dTo = dayjs();
			dTo = dTo.format('YYYY-MM-DD HH:mm')

			setSelectedFromDateMid(dFrom)
			setSelectedToDateMid(dTo)

		}
		else if(value == 1){
			let dFromMid = dayjs();
			dFromMid = dFromMid.format('YYYY-MM-DD 00:00')
			let dToMid = dayjs();
			dToMid = dToMid.format('YYYY-MM-DD 23:59')

			setSelectedFromDateMid(dFromMid)
			setSelectedToDateMid(dToMid)
		}
		else if(value == 2){
			//yesterday
			let dFromMid = dayjs();
			dFromMid = dFromMid.subtract(1, 'day')
			dFromMid = dFromMid.format('YYYY-MM-DD 00:00')

			let dToMid = dayjs();
			dToMid = dToMid.subtract(1, 'day');
			dToMid = dToMid.format('YYYY-MM-DD 23:59')

			setSelectedFromDateMid(dFromMid)
			setSelectedToDateMid(dToMid)
		}
		else if(value == 3){
			// This week
			let dFromMid = dayjs();
			while(dFromMid.day() != 1){
				dFromMid = dFromMid.subtract(1, 'day');
			}
			dFromMid = dFromMid.format('YYYY-MM-DD 00:00')

			let dToMid = dayjs();
			while(dToMid.day() != 0){
				dToMid = dToMid.add(1, 'day');
			}
			dToMid = dToMid.format('YYYY-MM-DD 23:59')

			setSelectedFromDateMid(dFromMid)
			setSelectedToDateMid(dToMid)
		}
		else if(value == 4){
			// last week
			let dFromMid = dayjs();
			dFromMid = dFromMid.subtract(1, 'week');
			while(dFromMid.day() != 1){
				dFromMid = dFromMid.subtract(1, 'day');
			}
			dFromMid = dFromMid.format('YYYY-MM-DD 00:00')

			let dToMid = dayjs();
			dToMid = dToMid.subtract(1, 'week');
			while(dToMid.day() != 0){
				dToMid = dToMid.add(1, 'day');
			}
			dToMid = dToMid.format('YYYY-MM-DD 23:59')

			setSelectedFromDateMid(dFromMid)
			setSelectedToDateMid(dToMid)
		}
		else if(value == 5){
			// this month
			let dFromMid = dayjs();
			dFromMid = dFromMid.date(1)
			dFromMid = dFromMid.format('YYYY-MM-DD 00:00')

			let dToMid = dayjs();
			dToMid = dToMid.date(1)
			dToMid = dToMid.add(1, 'month');
			dToMid = dToMid.subtract(1, 'day');
			dToMid = dToMid.format('YYYY-MM-DD 23:59')

			setSelectedFromDateMid(dFromMid)
			setSelectedToDateMid(dToMid)
		}		
		else if(value == 6){
			// last month
			let dFromMid = dayjs();
			dFromMid = dFromMid.date(1)
			dFromMid = dFromMid.subtract(1, 'month');
			dFromMid = dFromMid.format('YYYY-MM-DD 00:00')

			let dToMid = dayjs();
			dToMid = dToMid.date(1)
			dToMid = dToMid.subtract(1, 'day');
			dToMid = dToMid.format('YYYY-MM-DD 23:59')

			setSelectedFromDateMid(dFromMid)
			setSelectedToDateMid(dToMid)
		}	
		else if(value == 7){
			let dFromMid = dayjs();
			dFromMid = dFromMid.month(0)
			dFromMid = dFromMid.date(1);
			dFromMid = dFromMid.format('YYYY-MM-DD 00:00')

			let dToMid = dayjs();
			dToMid = dToMid.month(0)
			dToMid = dToMid.date(1);
			dToMid = dToMid.add(1, 'year');
			dToMid = dToMid.subtract(1, 'day');
			dToMid = dToMid.format('YYYY-MM-DD 23:59')

			setSelectedFromDateMid(dFromMid)
			setSelectedToDateMid(dToMid)
		}
		else if(value == 8){
			// last year
			let dFromMid = dayjs();
			dFromMid = dFromMid.subtract(1, 'year');
			dFromMid = dFromMid.month(0)
			dFromMid = dFromMid.date(1);

			if(dFromMid.year() <= 2023){
				dFromMid = dFromMid.month(7)
				dFromMid = dFromMid.date(21);
			}


			dFromMid = dFromMid.format('YYYY-MM-DD 00:00')

			let dToMid = dayjs();
			dToMid = dToMid.subtract(1, 'year');
			dToMid = dToMid.month(11)
			dToMid = dToMid.date(1);
			dToMid = dToMid.add(1, 'month');
			dToMid = dToMid.subtract(1, 'day');

			dToMid = dToMid.format('YYYY-MM-DD 23:59')

			setSelectedFromDateMid(dFromMid)
			setSelectedToDateMid(dToMid)
		}
		setMidType(value);
	}

	const change_from_date = (value) => {
		setSelectedFromDateMid(value);
		setCustomFrom(value);
		if(custom_to == null){
			setSelectedToDateMid(value);
			setCustomTo(value);
		}
	}

	const change_to_date = (value) => {
		setSelectedToDateMid(value);
		setCustomTo(value);
	}


	let genLbl = ''
	if(midType == 0){
		genLbl = 'in the last 30 minutes'
	}
	else if(midType == 1){
		genLbl = 'today'
	}
	else if(midType == 2){
		genLbl = 'yesterday';
	}
	else if(midType == 3){
		genLbl = 'this week'
	}
	else if(midType == 4){
		genLbl = 'last week';
	}
	else if(midType == 5){
		genLbl = 'this month';
	}
	else if(midType == 6){
		genLbl = 'last month'
	}	
	else if(midType == 7){
		genLbl = 'this year'
	}	
	else if(midType == 8){
		genLbl = 'last year'
	}


	let uli_tk = getCookie('usign',true);
	let role_id = uli_tk["role_id"]; 
	

	let topDests = null;
	let topRegions = null;
	let topResorts = null;

	if(prodType == 2){
		//if package
		topRegions = <div><RegionTopList max_items={10} prod_type={prodType} token={user_token} date_from={selected_from_date_mid} date_to={selected_to_date_mid}></RegionTopList></div>

	}else{
		topDests = <div><DestTopList max_items={10} prod_type={prodType} token={user_token} date_from={selected_from_date_mid} date_to={selected_to_date_mid}></DestTopList></div>
		topRegions = <div><RegionTopList max_items={10} prod_type={prodType} token={user_token} date_from={selected_from_date_mid} date_to={selected_to_date_mid}></RegionTopList></div>
		topResorts = <div><ResortTopList max_items={10} prod_type={prodType} token={user_token} date_from={selected_from_date_mid} date_to={selected_to_date_mid}></ResortTopList></div>
	}

	let abandonedCount = null;
	let abandonedCost = null;
	let abandonedTotalCost = null;
	if(midType <=4){
		abandonedCount = <div><SessionCountBlock token={user_token} date_from={selected_from_date_mid} date_to={selected_to_date_mid} prod_type={prodType} ></SessionCountBlock></div>
		abandonedCost = <div><SessionAvgCostBlock token={user_token} date_from={selected_from_date_mid} date_to={selected_to_date_mid} prod_type={prodType} ></SessionAvgCostBlock></div>
		abandonedTotalCost = <div><SessionTotalCostBlock token={user_token} date_from={selected_from_date_mid} date_to={selected_to_date_mid} prod_type={prodType} ></SessionTotalCostBlock></div>
	}

	let dateFilterSection = null;

	if(midType == -1){
dateFilterSection = <div>
	<div>


	<div className='filter_field' style={{marginBottom: '15px'}}>
	<div>From:</div>
	<div><YMDPicker id='sel_bk_from' key='sel_bk_from_k' enable_time={true} c initalValue={null} onChange={(value) => change_from_date(value)}></YMDPicker> </div>
	</div>

	<div className='filter_field'>
	<div>To:</div>
	<div><YMDPicker id='sel_bk_to' key='sel_bk_to_k' enable_time={true} initalValue={null} onChange={(value) => change_to_date(value)}></YMDPicker></div>
	</div>

	</div>
	</div>
	}

	return (
		<>
        <div style={{display:'flex', flexWrap:'wrap', justifyContent: 'center', margin:'50px 50px 50px 50px', columnGap: '2em', rowGap: '2em'}}>

		<div className='fitler_sec'>
			<div className='filter_field'>
			<div>Show data for:</div>
			<div className='filter_field_in'>
				<select onChange={(e) => updateMidRange(e.target.value)}>
					<option value="-1">Custom</option>
					<option value="0">Last 30 minutes</option>
					<option selected={true} value="1">Today</option>
					<option value="2">Yesterday</option>
					<option value="3">This week</option>
					<option value="4">Last week</option>
					<option value="5">This Month</option>
					<option value="6">Last Month</option>
					<option value="7">This Year</option>	
					<option value="8">Last Year</option>
				</select>
			</div>
			</div>

			{dateFilterSection}

			<div className='filter_field'>
			<div>Product Type:</div>
			<div className='filter_field_in'>
				<select onChange={(e) => updateProductType(e.target.value)}>
					<option value="">All</option>
					<option value="-1">Dynamic Package</option>
					<option value="0">Beach</option>
					<option value="1">City</option>
					<option value="2">Package</option>
				</select>
			</div>
			</div>
		</div>

			



			<div>
				<SearchCountBlock token={user_token} prod_type={prodType} title={'Search Results'} sub_title={'Users on search page ' + genLbl} date_from={selected_from_date_mid} date_to={selected_to_date_mid}></SearchCountBlock>
			</div>

			<div>
				<AccommodationCountBlock token={user_token} prod_type={prodType} title={'Hotel Page'} sub_title={'Users on accommodation page ' + genLbl} date_from={selected_from_date_mid} date_to={selected_to_date_mid}></AccommodationCountBlock>
			</div>

			<div>
				<JourneyCountBlock token={user_token} prod_type={prodType} title={'Select Flight and Hotel'} sub_title={'Users on select flight and hotel ' + genLbl} journey_step={1} date_from={selected_from_date_mid} date_to={selected_to_date_mid}></JourneyCountBlock>
			</div>

			<div>
				<JourneyCountBlock token={user_token} prod_type={prodType} title={'Choose Extras'} sub_title={'Users on choose extras ' + genLbl} journey_step={2} date_from={selected_from_date_mid} date_to={selected_to_date_mid}></JourneyCountBlock>
			</div>

			<div>
				<JourneyCountBlock token={user_token} prod_type={prodType} title={'Passenger Details'} sub_title={'Users on passenger details ' + genLbl} journey_step={3} date_from={selected_from_date_mid} date_to={selected_to_date_mid}></JourneyCountBlock>
			</div>

			<div>
				<JourneyCountBlock token={user_token} prod_type={prodType} title={'Payment Page'} sub_title={'Users on payment page ' + genLbl} journey_step={4} date_from={selected_from_date_mid} date_to={selected_to_date_mid}></JourneyCountBlock>
			</div>
    




			<div>
				<SearchDepMonthTable max_items={10} prod_type={prodType} token={user_token} date_from={selected_from_date_mid} date_to={selected_to_date_mid}></SearchDepMonthTable>
			</div>

	

			<div>
				<BookingCountBlock token={user_token} prod_type={prodType} title={'Bookings'} sub_title={'Bookings ' +genLbl } date_from={selected_from_date_mid} date_to={selected_to_date_mid}></BookingCountBlock>
			</div>

			<div>
				<AccTopLists title={"Most Viewed Hotels"} prod_type={prodType} max_items={10} token={user_token} date_from={selected_from_date_mid} date_to={selected_to_date_mid}></AccTopLists>
			</div>

			{topDests}
			{topRegions}
			{topResorts}

			<div>
				<RouteTopList max_items={10} prod_type={prodType} token={user_token} date_from={selected_from_date_mid} date_to={selected_to_date_mid}></RouteTopList>
			</div>

			<div>
				<BookingCostBlock token={user_token} prod_type={prodType} date_from={selected_from_date_mid} date_to={selected_to_date_mid}></BookingCostBlock>
			</div>

			<div>
				<BookingAvgCostBlock token={user_token} prod_type={prodType} date_from={selected_from_date_mid} date_to={selected_to_date_mid}></BookingAvgCostBlock>
			</div>		

			{abandonedCount}
			{abandonedCost}
			{abandonedTotalCost}
			
		    </div>


		</>
	);
};

export default Home;
