import {graph2, api} from '../utils/shared'



export const search_total_view_count = async(date_from=null, date_to=null, prod_type=null, token) => {

    const pd = {
        "date_from": date_from,
        "date_to": date_to,
        "type": prod_type
    }

    return new Promise(async (resolve, reject) => {
        await api(
            "/search_total_view_count/", pd,
            "POST",
            (d) => {
                d.text().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const search_count_by_month = async(date_from=null, date_to=null, product_type=null, token) => {

    const pd = {
        "date_from": date_from,
        "date_to": date_to,
        "type": product_type
    }

    return new Promise(async (resolve, reject) => {
        await api(
            "/search_count_by_month/", pd,
            "POST",
            (d) => {
                d.text().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}




export const search_destination_count = async(duration=null, departing_from=null, departing_to=null, date_from=null, date_to=null, adults=null, children=null, rooms=null, product_type=null, token) => {
    return new Promise(async (resolve, reject) => {

        const pd = {
            "duration": duration,
            "departing_from": departing_from,
            "departing_to": departing_to,
            "date_from": date_from,
            "date_to": date_to,
            "adults": adults,
            "children": children,
            "rooms": rooms,
            "type": product_type
        }

        await api(
            "/search_destination_count/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const search_region_count = async(duration=null, departing_from=null, departing_to=null, date_from=null, date_to=null, adults=null, children=null, rooms=null, product_type=null, token) => {
    return new Promise(async (resolve, reject) => {

        const pd = {
            "duration": duration,
            "departing_from": departing_from,
            "departing_to": departing_to,
            "date_from": date_from,
            "date_to": date_to,
            "adults": adults,
            "children": children,
            "rooms": rooms,
            "type": product_type
        }

        await api(
            "/search_region_count/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const search_resort_count = async(duration=null, departing_from=null, departing_to=null, date_from=null, date_to=null, adults=null, children=null, rooms=null, product_type=null, token) => {
    return new Promise(async (resolve, reject) => {

        const pd = {
            "duration": duration,
            "departing_from": departing_from,
            "departing_to": departing_to,
            "date_from": date_from,
            "date_to": date_to,
            "adults": adults,
            "children": children,
            "rooms": rooms,
            "type": product_type
        }

        await api(
            "/search_resort_count/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const search_destination_count_by_month = async(duration=null, date_from=null, date_to=null, adults=null, children=null, rooms=null, year=null, product_type=null, token) => {
    return new Promise(async (resolve, reject) => {

        const pd = {
            "duration": duration,
            "departing_from": null,
            "departing_to": null,
            "date_from": date_from,
            "date_to": date_to,
            "adults": adults,
            "children": children,
            "rooms": rooms,
            "year": year,
            "type": product_type
        }

        await api(
            "/search_destination_count_by_month/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}
export const search_region_count_by_month = async(duration=null, date_from=null, date_to=null, adults=null, children=null, rooms=null, year=null, product_type=null, token) => {
    return new Promise(async (resolve, reject) => {

        const pd = {
            "duration": duration,
            "departing_from": null,
            "departing_to": null,
            "date_from": date_from,
            "date_to": date_to,
            "adults": adults,
            "children": children,
            "rooms": rooms,
            "year": year,
            "type": product_type
        }

        await api(
            "/search_region_count_by_month/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const search_resort_count_by_month = async(duration=null, date_from=null, date_to=null, adults=null, children=null, rooms=null, year=null, product_type=null, token) => {
    return new Promise(async (resolve, reject) => {

        const pd = {
            "duration": duration,
            "departing_from": null,
            "departing_to": null,
            "date_from": date_from,
            "date_to": date_to,
            "adults": adults,
            "children": children,
            "rooms": rooms,
            "year": year,
            "type": product_type
        }

        await api(
            "/search_resort_count_by_month/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const search_destination_by_departure_point_count = async(duration=null, departing_from=null, departing_to=null, date_from=null, date_to=null, adults=null, children=null, rooms=null, product_type=null, token) => {


    const pd = {
        "duration": duration,
        "departing_from": departing_from,
        "departing_to": departing_to,
        "date_from": date_from,
        "date_to": date_to,
        "adults": adults,
        "children": children,
        "rooms": rooms,
        "limit": null,
        "type": product_type
    }

    return new Promise(async (resolve, reject) => {
        await api(
            "/search_destination_by_departure_point_count/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const search_region_by_departure_point_count = async(duration=null, departing_from=null, departing_to=null, date_from=null, date_to=null, adults=null, children=null, rooms=null, product_type=null, token) => {


    const pd = {
        "duration": duration,
        "departing_from": departing_from,
        "departing_to": departing_to,
        "date_from": date_from,
        "date_to": date_to,
        "adults": adults,
        "children": children,
        "rooms": rooms,
        "limit": null,
        "type": product_type
    }

    return new Promise(async (resolve, reject) => {
        await api(
            "/search_region_by_departure_point_count/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const search_resort_by_departure_point_count = async(duration=null, departing_from=null, departing_to=null, date_from=null, date_to=null, adults=null, children=null, rooms=null, product_type=null, token) => {


    const pd = {
        "duration": duration,
        "departing_from": departing_from,
        "departing_to": departing_to,
        "date_from": date_from,
        "date_to": date_to,
        "adults": adults,
        "children": children,
        "rooms": rooms,
        "limit": null,
        "type": product_type
    }

    return new Promise(async (resolve, reject) => {
        await api(
            "/search_resort_by_departure_point_count/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}