import React, {useEffect, useState} from 'react';
import DestinationCountMonth from '../../../../components/charts/destination/destination_count_month'
import RegionCountMonth from '../../../../components/charts/destination/region_count_month'
import ResortCountMonth from '../../../../components/charts/destination/resort_count_month'
import YMDPicker from '../../../../components/ymd_picker'


const DestStatsMonth = ({user_token, set_menu, authorize}) => {


    const [selected_adults, setSelectedAdults] = useState(null)
    const [selected_children, setSelectedChildren] = useState(null)
    const [selected_rooms, setSelectedRooms] = useState(null)
    const [selected_dur, setSelectedDuration] = useState(null)

    const [selected_year, setSelectedYear] = useState(null)

	const [selected_from_date, setSelectedFromDate] = useState(null)
	const [selected_to_date, setSelectedToDate] = useState(null)


	const change_from_date = (v) => {
		setSelectedFromDate(v)
	}	
	
	const change_to_date = (v) => {
		setSelectedToDate(v)
	}

    const change_duration = (v) => {
        let val = Number(v)
        if(val == -1){
            setSelectedDuration(null)
        }
        else{
            setSelectedDuration(val);
        }
    }

    const change_adults = (v) => {
        let val = Number(v)
        if(val == -1){
            setSelectedAdults(null)
        }
        else{
            setSelectedAdults(val);
        }
    }
    const change_children = (v) => {
        let val = Number(v)
        if(val == -1){
            setSelectedChildren(null)
        }
        else{
            setSelectedChildren(val);
        }
    }
    const change_rooms = (v) => {
        let val = Number(v)
        if(val == -1){
            setSelectedRooms(null)
        }
        else{
            setSelectedRooms(val);
        }
    }  
    const change_year = (v) => {
        let val = Number(v)
        if(val == -1){
            setSelectedYear(null)
        }
        else{
            setSelectedYear(val);
        }
    }

	useEffect(() =>{
        authorize(5);
		const lnks = [{'url': '/stats', 'name': 'Stats'},  {'url': '/stats/destination', 'name': 'Destinations'}, {'url': '/stats/destination/month', 'name': 'Month'}, {'url': '/stats/destination/departure_point', 'name': 'Departure Point'}]
		set_menu(lnks)
    }, [])

	return (
        <>
        <div className='fitler_sec'>

            <div className='filter_field'>
                <div>
                    Duration 
                </div>
                <div>
                    <select onChange={(e) => change_duration(e.target.value)}>
                        <option value="-1">Any</option>
                        <option value="3" >3</option>
                        <option value="7" >7</option>
                        <option value="10" >10</option>
                        <option value="14" >14</option>
                    </select>
                </div>
            </div>

            <div className='filter_field'>
                <div>
                    Adults 
                </div>
                <div>
                    <select onChange={(e) => change_adults(e.target.value)}>
                        <option value="-1">Any</option>
                        <option value="1" >1</option>
                        <option value="2" >2</option>
                        <option value="3" >3</option>
                        <option value="4" >4</option>
                        <option value="5" >5</option>
                        <option value="6" >6</option>
                        <option value="7" >7</option>
                        <option value="8" >8</option>
                        <option value="9" >9</option>
                        <option value="10" >10</option>
                    </select>
                </div>
            </div>

            <div className='filter_field'>
                <div>
                    Children 
                </div>
                <div>
                    <select onChange={(e) => change_children(e.target.value)}>
                        <option value="-1">Any</option>
                        <option value="1" >1</option>
                        <option value="2" >2</option>
                        <option value="3" >3</option>
                        <option value="4" >4</option>
                        <option value="5" >5</option>
                        <option value="6" >6</option>
                        <option value="7" >7</option>
                        <option value="8" >8</option>
                        <option value="9" >9</option>
                        <option value="10" >10</option>
                    </select>
                </div>
            </div>

            <div className='filter_field'>
                <div>
                    Rooms 
                </div>
                <div>
                    <select onChange={(e) => change_rooms(e.target.value)}>
                        <option value="-1">Any</option>
                        <option value="1" >1</option>
                        <option value="2" >2</option>
                        <option value="3" >3</option>
                    </select>
                </div>
            </div>

            <div className='filter_field'>
                <div>
                    Year 
                </div>
                <div>
                    <select onChange={(e) => change_year(e.target.value)}>
                        <option value="-1">Current year</option>
                        <option value="2024" >2024</option>
                        <option value="2025" >2025</option>
                        <option value="2026" >2026</option>
                    </select>
                </div>
            </div>

            <div style={{height: '105px'}}>
            <div>
                <div className='filter_field' style={{marginBottom: '15px'}}>
                    <div>
                        Date  
                    </div>
                    <div>
                    <YMDPicker id='sel_bk_from' key='sel_bk_from_k' initalValue={selected_from_date} onChange={(value) => change_from_date(value)}></YMDPicker> 
                    </div>
                </div>
                <div className='filter_field' style={{marginBottom: '15px'}}>
                    <div>
                        To
                    </div>
                    <div>
                    <YMDPicker id='sel_bk_to' key='sel_bk_to_k' initalValue={selected_to_date} onChange={(value) => change_to_date(value)}></YMDPicker>
                    </div>
                </div>
            </div>
            </div>

        </div>

        <div style={{display:'flex', flexWrap:'wrap', margin:'50px 50px 50px 50px'}}>
        <div>
            <div style={{textAlign:'center'}}>Destination search count</div>
            <DestinationCountMonth duration={selected_dur} date_from={selected_from_date} 
        date_to={selected_to_date} adults={selected_adults} children={selected_children} rooms={selected_rooms} year={selected_year} token={user_token}></DestinationCountMonth>
        </div>

        <div>
            <div style={{textAlign:'center'}}>Region search count</div>
            
            <RegionCountMonth duration={selected_dur} date_from={selected_from_date} 
        date_to={selected_to_date} adults={selected_adults} children={selected_children} rooms={selected_rooms} year={selected_year} token={user_token}></RegionCountMonth>
        </div>

        <div>
            <div style={{textAlign:'center'}}>Resort search count</div>
            <ResortCountMonth duration={selected_dur} date_from={selected_from_date} 
        date_to={selected_to_date} adults={selected_adults} children={selected_children} rooms={selected_rooms} year={selected_year} token={user_token}></ResortCountMonth>
        </div>

        </div>
        </>



	);
};

export default DestStatsMonth;
