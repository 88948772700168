
import React, {useEffect, useState} from 'react';
import { Chart } from 'react-google-charts'
import {search_region_count} from '../../../service/search'

const RegionCount = ({prod_type=null, duration=null, departing_from=null, departing_to=null, date_from=null, date_to=null, adults=null, children=null, rooms=null, token}) => {

	const [region_d, set_d_view] = useState(null);
    

    const  popd = (duration, departing_from, departing_to, date_from, date_to, adults, children, rooms, prod_type) => {
        const limit = 8;
        search_region_count(duration, departing_from, departing_to, date_from, date_to, adults, children, rooms, prod_type, token).then((d) => {
            if(d != null){
                const td= [];
                td.push(["Destination", "Count"])
                for(const x of d){
                    if(x.id == 0){
                        continue
                    }
                    
                    if(td.length == limit){
                        break;
                    }
                    let lbl = x.name;
                    td.push([lbl, x.count]);
                }
                set_d_view(td);
            }
        });
    }

    useEffect(() =>{
        popd(duration, departing_from, departing_to, date_from, date_to, adults, children, rooms, prod_type);
    }, [])

    useEffect(() =>{
        popd(duration, departing_from, departing_to, date_from, date_to, adults, children, rooms, prod_type);
    }, [duration, adults, children, rooms, prod_type])    

    useEffect(() =>{
        if(date_from == null || date_to == null){
            return
        }
        popd(duration, departing_from, departing_to, date_from, date_to, adults, children, rooms, prod_type);

    }, [date_from, date_to])    

    useEffect(() =>{
        if(departing_from == null || departing_to == null){
            return
        }
        popd(duration, departing_from, departing_to, date_from, date_to, adults, children, rooms, prod_type);
    }, [departing_from, departing_to])   

    return (<div>
        <Chart chartType="ColumnChart" 
        width="700px" 
        height="400px" 
        data={region_d} />
    </div>)

}

export default RegionCount