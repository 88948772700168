import React, {useEffect, useState} from 'react';
import {promos, promo_remove} from '../../service/promo';
import Nav from '../../components/nav';


const Promos = ({user_token, set_menu, authorize}) => {

    const [d_view, setDataView] = useState(null);

    useEffect(() =>{

		authorize(1);

		let f = "id code expires amount type";

		const lnks = [{'url': '/', 'name': 'Home'}, {'url': '/promos/add', 'name': 'Create Promotional Code'}]
		
		set_menu(lnks)

		let x = promos(f, user_token).then((r) =>{
			if(r == null){
				return
			}	
			if(r.errors != null){
					if(r.errors.length > 0){
						alert(r.errors[0].message)
						return
					}
					alert("Unexpected Error Contact HWT support...")
					return
			}
			if(r.promotional_codes.error != null){
				alert(r.deposits.error.message)
				return
			}
			setDataView(r.promotional_codes.result)
		}
		);
    }, [])

	const remove = async (id) => {
		let f = "id code expires amount type";

		const r = await promo_remove(id, f, user_token);
		if(r.errors != null){
			if(r.errors.length > 0){
				alert(r.errors[0].message)
				return
			}
			alert("Unexpected Error Contact HWT support...")
			return
		}
		if(r.promotional_code_remove.error != null){
			alert(r.deposits.error.message)
			return
		}
		setDataView(r.promotional_code_remove.result)
	}

    let tbl_view = null;
	if(d_view != null){

        if(d_view.length == 0){
            return (<div>
                <div className='nothing_here'>
                <p>Nothing to see here <br></br>
                   It looks like you have no promotional codes setup. Click <a href='/promos/add'>here</a> to create a one.</p>
                </div>
            </div>)
        }

		let rows = []
		for(let x of d_view){

            let dtype = '';
            if(x.type == 0){
                dtype = '£'
            }
            else{
                dtype = '%'
            }


			rows.push(<tr>
				<td>
					{x.code}
				</td>
				<td>
					{dtype}
				</td>
				<td>
					{x.amount}
				</td>
                <td>
					{x.expires}
				</td>
				<td>
					<a href={'/promos/add?pid=' + x.id}>Edit</a>
				</td>
                <td>
					<a style={{cursor:"pointer", textDecoration:'underline', color:'red'}} onClick={() => remove(x.id)}>Remove</a>
				</td>
			</tr>)
		}

		tbl_view = <table>
				<thead>
					<td>
						Code
					</td>
                    <td>
                        type 
					</td>
                    <td>
                        amount 
					</td>
					<td>
						expires 
					</td>
                    <td></td>
                    <td></td>
				</thead>
			<tbody>
				{rows}
			</tbody>
		</table>
	}



    return(
	<div className='rule_tbl'>
        {tbl_view}
    </div>);


};

export default Promos;