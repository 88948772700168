import React, {useEffect, useState} from 'react';
import { Chart } from 'react-google-charts'
import {accommodation_views} from '../../../service/accommodation'

const AccommodationHits = ({prod_type=null, destination_ids=null, region_ids=null, date_from=null, date_to=null, token}) => {

	const [acc_hit_view, set_acc_hit_view] = useState(null);


	const  popd = (destination_ids, region_ids, date_from, date_to, prod_type) => {
		accommodation_views(destination_ids, region_ids, date_from, date_to, 6, prod_type, token).then((d) => {
			if(d != null){
				const td= [];
				td.push(["Accommodation", "Views"])
				for(const x of d){
					td.push([x.name, x.count]);
				}
				set_acc_hit_view(td);
			}
		});
	}

    useEffect(() =>{
		popd(destination_ids, region_ids, date_from, date_to, prod_type);
    }, [])

    useEffect(() =>{
		popd(destination_ids, region_ids, date_from, date_to, prod_type);
    }, [destination_ids, region_ids])    
    
    useEffect(() =>{

        if(date_from == null || date_to == null){
            return
        }
		
		popd(destination_ids, region_ids, date_from, date_to, prod_type);

    }, [date_from, date_to])

    useEffect(() =>{
      popd(destination_ids, region_ids, date_from, date_to, prod_type);
      }, [prod_type])   
    

    return (<div>
        <Chart
        chartType="PieChart"
        data={acc_hit_view}
        options={{}}
        width="700px"
        height="400px"
        legendToggle
        />
    </div>)

}

export default AccommodationHits

