import React, {useEffect, useState} from 'react';
import {margins, margin_remove} from '../../service/margin'
import './style.css';


const Margins = ({user_token, set_menu, authorize}) => {

    const [d_view, setDataView] = useState(null);

    useEffect(() =>{

		authorize(1);

		const lnks = [{'url': '/', 'name': 'Home'}, {'url': '/margins/add', 'name': 'Create Margin'}]
		
		set_menu(lnks)

		let f = "id description margin_type product_type order"

		let x = margins(f, user_token).then((r) =>{
			if(r == null){
				return
			}	
			if(r.errors != null){
					if(r.errors.length > 0){
						alert(r.errors[0].message)
						return
					}
					alert("Unexpected Error Contact HWT support...")
					return
			}
			if(r.margins.error != null){
				alert(r.margins.error.message)
				return
			}
			setDataView(r.margins.result)
		}
		);
    }, [])

	const remove = async (id) => {
		let f = "id description margin_type product_type order"

		const r = await margin_remove(id, f, user_token);
		if(r.errors != null){
			if(r.errors.length > 0){
				alert(r.errors[0].message)
				return
			}
			alert("Unexpected Error Contact HWT support...")
			return
		}
		if(r.margin_remove.error != null){
			alert(r.margin_remove.error.message)
			return
		}
		setDataView(r.margin_remove.result)
	}

    let tbl_view = null;
	if(d_view != null){

        if(d_view.length == 0){
            return (<div>
                <div className='nothing_here'>
                    Nothing to see here <br></br>
                    It looks like you have no discount rules setup. Click <a href='/margins/add'>here</a> to create a rule.
                </div>
            </div>)
        }

		let rows = []
		for(let x of d_view){

			let prod_type = '';
			if(x.product_type == 0){
				prod_type = "Accommodation";
			}
            else if(x.product_type == 1){
                prod_type = "Flights";
            }
            else if(x.product_type == 2){
                prod_type = "Flight Extras";
            }
            else if(x.product_type == 3){
                prod_type = "Transfers";
            }
            else if(x.product_type == 4){
                prod_type = "Vehicle Hire";
            }

            let dtype = '';
            if(x.margin_type == 0){
                dtype = '£'
            }
            else{
                dtype = '%'
            }


			rows.push(<tr>
				<td>
					{x.description}
				</td>
				<td>
					{dtype}
				</td>
				<td>
					{prod_type}
				</td>
				<td>
					<a href={'/margins/add?mid=' + x.id}>Edit</a>
				</td>
                <td>
					<a style={{cursor:"pointer", textDecoration:'underline', color:'red'}} onClick={() => remove(x.id)}>Remove</a>
				</td>
			</tr>)
		}

		tbl_view = <table>
				<thead>
					<td>
						Description
					</td>
					<td>
						Type 
					</td>
					<td>
						Product
					</td>
					<td></td>
                    <td></td>
				</thead>
			<tbody>
				{rows}
			</tbody>
		</table>
	}

    return(<div className='rule_tbl'>
        {tbl_view}
    </div>);


};

export default Margins;