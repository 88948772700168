import React, {useEffect, useState} from 'react';
import {user_password_reset_request} from '../../service/sub_user'

const ResetReq = ({
}) => {

    const [email_address, setEmail] = useState('');

    useEffect(() =>{
    }, [])

    const reset_req = async() => {
        let value = await user_password_reset_request(email_address)
        if(value["success"]){
            setEmail("");
            alert("Reset email sent.")
        }
        else{
            setEmail("");
            alert("Failed to send reset link.")
        }

    }


	return (
		<section>
			<div className='logIn' style={{display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
				
            <div style={{marginTop:'50px', alignSelf:'center', padding:'5px 5px 5px 5px'}}>
                <div style={{alignSelf:'center'}}>
                <div style={{display: 'inline-flex', width:'90px', paddingTop:'10px'}}>
                    Email Address
                </div>
                <div style={{display: 'inline-flex', paddingTop:'10px'}}>
                    <input value={email_address} onChange={(e) => setEmail(e.target.value)}></input>
                </div>
                </div>


                <div style={{alignSelf:'center'}}>
                    <div style={{display: 'inline-flex', paddingTop:'10px'}}>
                    <button onClick={(e) => reset_req()}>Reset Password</button>
                    </div>
                </div> 

         

                </div>
			</div>

		</section>
	);
};

export default ResetReq;
