
import React, {useEffect, useState} from 'react';
import {booking_avg_cost} from '../../../service/booking'

const BookingAvgCostBlock = ({prod_type=null, date_from=null, date_to=null, title=null, sub_title=null, token}) => {

    const [booking_d, set_data] = useState(null);

    const  popd = (date_from, date_to, prod_type) => {
        const limit = 8;
        booking_avg_cost(null, date_from, date_to, null, null, prod_type, token).then((d) => {
            if(d != null){
                set_data(d)
            }
            else{
                set_data(0)
            }
        });
    }
    

    let cTitle = 'Average Booking Value';
    if(title != null){
        cTitle = title;
    }

    let cSubTitle = '';
    if(sub_title != null){
        cSubTitle = sub_title;
    }


    useEffect(() =>{
        popd(date_from, date_to, prod_type);
    }, [])

    useEffect(() =>{
        if(date_from == null || date_to == null){
            return
        }
        popd(date_from, date_to, prod_type);

    }, [date_from, date_to])   

    useEffect(() =>{
        popd(date_from, date_to, prod_type);

    }, [prod_type])  


    if(booking_d == null){
        return null;
    }


    const booking_d_r = Math.round(booking_d);

    const formatted = Number(booking_d_r).toLocaleString('en-US');



    return (<div className='countBlock'>
        <div className='countBlockTitle'>
            {cTitle}
        </div>
        <div className='countBlockContent'>
            <div className='info'>{formatted}</div>
            <div className='info infosmall'>{cSubTitle}</div>
        </div>
    </div>)

}

export default BookingAvgCostBlock
