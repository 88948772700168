
import React, {useEffect, useState} from 'react';
import {accommodation_views} from '../../../service/accommodation'

const AccTopLists = ({prod_type=null, date_from=null, date_to=null, title=null, max_items=10, token}) => {

    const [listData, set_list_data] = useState(null);

    const  popd = (date_from, date_to, prod_type) => {

        accommodation_views(null, null, date_from, date_to, max_items, prod_type, token).then((d) => {
            if(d != null){
                set_list_data(d)
            }
        });
    }
    

    let cTitle = 'Top Viewed Accommodations';
    if(title != null){
        cTitle = title;
    }

    useEffect(() =>{
        popd(date_from, date_to, prod_type);
    }, [])

    useEffect(() =>{
        if(date_from == null || date_to == null){
            return
        }
        popd(date_from, date_to, prod_type);

    }, [date_from, date_to])  
      
    useEffect(() =>{
        popd(date_from, date_to, prod_type);
    }, [prod_type])  

    if(listData == null){
        return null;
    }

    const lItems = [];
    for(const x of listData){

        let strName = x.name;
        if(x.name.length > 31){
            strName = x.name.substring(0, 31) + '...'
        }
        lItems.push(<li>{strName}</li>);
    }

    return (<div className='listBlock'>
        <div className='listBlockTitle'>
            {cTitle}
        </div>
        <div className='listBlockContent'>
            <ul>
                {lItems}
            </ul>

        </div>
    </div>)

}

export default AccTopLists
