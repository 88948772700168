import React, {useEffect, useState} from 'react';
import { Chart } from 'react-google-charts'
import {journey_progress} from '../../../service/journey'

const JourneyProgress = ({prod_type=null, date_from=null, date_to=null, adults=null, children=null, rooms=null, token}) => {

	const [journey_prog, set_journey_prog] = useState(null);

    const  popd = (date_from, date_to, adults, children, rooms, prod_type) => {
        journey_progress(date_from, date_to, adults, children, rooms, prod_type, token).then((d) => {
			if(d != null){
				const td= [];
				td.push(["Step", "Users"])
				for(const x of d){

                    let lbl = ""
                    if(x.step == 1){
                        lbl = "Flight & room";
                    }
                    else if(x.step == 2){
                        lbl = "Extras";
                    }
                    else if(x.step == 3){
                        lbl = "Passengers";
                    }
                    else if(x.step == 4){
                        lbl = "Payment";
                    }
                    else{
                        lbl = "Confirmation";
                    }
					td.push([lbl, x.count]);
				}
				set_journey_prog(td);
			}
		});
    }

    useEffect(() =>{
		popd(date_from, date_to, adults, children, rooms, prod_type);
    }, [])

    useEffect(() =>{
    	popd(date_from, date_to, adults, children, rooms, prod_type);
    }, [adults, children, rooms, prod_type])    
    
    useEffect(() =>{

        if(date_from == null || date_to == null){
            return
        }
        popd(date_from, date_to, adults, children, rooms, prod_type);
    }, [date_from, date_to])

    return (<div>
        <Chart chartType="ColumnChart" 
        width="700px" 
        height="400px" 
        data={journey_prog} />
    </div>)

}

export default JourneyProgress

