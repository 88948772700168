import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import {user_password_reset} from '../../service/sub_user'

const ResetPassword = ({
    log_in_func,
}) => {

    const [password, setPassword] = useState('');
    const [passwordConf, setPasswordConf] = useState('');

    useEffect(() =>{



    }, [])

    const reset_password = async() => {

        let x = queryString.parse(document.location.search);

        if(x.hrd == null){
            alert("Invalid url.");
            return
        }
        
        if(x.e == null){
            alert("Invalid url.");
            return
        }

        if(password != passwordConf){
            alert("Passwords do not match.");
            return
        }

        let token = window.atob(x.hrd)
        let email = window.atob(x.e)

        let value = await user_password_reset(email, token, password)

        if(value["success"]){
            log_in_func(value["username"], password);
        }
        else{
            alert("Failed to set password. Password may not meet minimum requirments (7 characters including 1 number) or reset token has expired."); 
        }

    }


	return (
		<section>
			<div className='logIn' style={{display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
				
            <div style={{marginTop:'50px', alignSelf:'center', padding:'5px 5px 5px 5px'}}>
                <div style={{alignSelf:'center'}}>
                <div style={{display: 'inline-flex', width:'90px', paddingTop:'10px'}}>
                Password
                </div>
                <div style={{display: 'inline-flex', paddingTop:'10px'}}>
                <   input onChange={(e) => setPassword(e.target.value)} type='password'></input>
                </div>
                </div>

                <div style={{alignSelf:'center'}}>
                <div style={{display: 'inline-flex', width:'90px', paddingTop:'10px'}}>
                    Confirm Password<br></br>
                </div>
                <div style={{display: 'inline-flex', paddingTop:'10px'}}>
                    <input onChange={(e) => setPasswordConf(e.target.value)} type='password'></input>
                </div>
                </div>

                <div style={{alignSelf:'center'}}>
                    <div style={{display: 'inline-flex', paddingTop:'10px'}}>
                    <button onClick={(e) => reset_password()}>Reset</button>
                    </div>
                </div> 
                </div>
			</div>

		</section>
	);
};

export default ResetPassword;
