
import React, {useEffect, useState} from 'react';
import {search_count_by_month} from '../../../service/search'

const SearchDepMonthTable = ({prod_type=null, date_from=null, date_to=null, title=null, sub_title=null, token}) => {

    const [d, set_data] = useState(null);

    const  popd = (date_from, date_to, prod_type) => {
        const limit = 8;
        search_count_by_month(date_from, date_to, prod_type, token).then((d) => {
            if(d != null){
                set_data(JSON.parse(d))
            }
        });
    }
    

    let cTitle = 'Most Searched Departure Months';
    if(title != null){
        cTitle = title;
    }

    useEffect(() =>{
        popd(date_from, date_to, prod_type);
    }, [])

    useEffect(() =>{
        if(date_from == null || date_to == null){
            return
        }
        popd(date_from, date_to, prod_type);

    }, [date_from, date_to])  

    useEffect(() =>{
        popd(date_from, date_to, prod_type);
    }, [prod_type])  

    if(d == null){
        return;
    }

    const tblRows = [];
    let c = 0;
    for(const x of d){

        if(c == 5){
            break;
        }
        tblRows.push(<tr><td>{x.month}</td><td>{x.count}</td></tr>);
        c++;
    }

    return (<div className='countBlock'>
        <div className='countBlockTitle'>
            {cTitle}
        </div>
        <div className='countBlockContent'>
            <div className='tblinf'>
                <table style={{border:'none', width: '100%'}}>
                    <tbody>
                        {tblRows}
                    </tbody>
                </table>
            
            </div>
        </div>
    </div>)

}

export default SearchDepMonthTable
