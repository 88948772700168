
import React, {useEffect, useState} from 'react';
import {search_resort_count} from '../../../service/search'

const ResortTopList = ({prod_type=null, date_from=null, date_to=null, title=null, max_items=10, token}) => {

    const [listData, set_list_data] = useState(null);

    const  popd = (date_from, date_to, prod_type) => {

        search_resort_count(null, null, null, date_from, date_to, null, null, null, prod_type, token).then((d) => {
            if(d != null){
                set_list_data(d)
            }
        });
    }
    

    let cTitle = 'Top Resorts';
    if(title != null){
        cTitle = title;
    }

    useEffect(() =>{
        popd(date_from, date_to, prod_type);
    }, [])

    useEffect(() =>{
        if(date_from == null || date_to == null){
            return
        }
        popd(date_from, date_to, prod_type);

    }, [date_from, date_to])    
    
    useEffect(() =>{
        popd(date_from, date_to, prod_type);
    }, [prod_type])  


    if(listData == null){
        return null;
    }

    const lItems = [];
    let c = 0;
    for(const x of listData){
        if(c == max_items){
            break;
        }
        lItems.push(<li>{x.name}</li>);
        c++;
    }

    return (<div className='listBlock'>
        <div className='listBlockTitle'>
            {cTitle}
        </div>
        <div className='listBlockContent'>
            <ul>
                {lItems}
            </ul>

        </div>
    </div>)

}

export default ResortTopList
