import React, { useState, useEffect,  } from "react";
import {all_accommodation_geo} from '../service/accommodation'
import Modal from 'react-modal';

const DestinationSelector = ({
  open=false,
  onChange,
  openChange,
  selDestinations,
  selRegions,
  selResorts,
  token
}) => {



    const [isOpen, setIsOpen] = useState(false);

    const [selectedDestinations, setSelectedDestinations] = useState(selDestinations);
    const [selectedRegions, setSelectedRegions] = useState(selRegions);
    const [selectedResorts, setSelectedResorts] = useState(selResorts);

    const [options, setOptions] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        all_accommodation_geo(token).then((d) => {
            if(d == null){
                return;
            }
            if(d.all_destinations_regions_resorts_acc.result == null){
                return;
            }
            setOptions(d.all_destinations_regions_resorts_acc.result);
        });
    }, [])

    useEffect(() => {

        if(!isOpen){
            return;
        }

        openChange(isOpen);

    }, [isOpen])

    useEffect(() => {
       if(!open){
        setIsOpen(false);
        return
       }
       setIsOpen(true);
    }, [open])

    useEffect(() => {
       if(searchValue.length > 0){
        setIsOpen(true);
       }
     }, [searchValue])


    const setOpen = () => {
        if(!isOpen){
            setIsOpen(true);
            return
        }
        setIsOpen(false);
    }

    useEffect(() => {

        
        if(onChange){
            onChange(selectedDestinations, selectedRegions, selectedResorts)
        }
  
      }, [selectedDestinations, selectedRegions, selectedResorts])

    useEffect(() => {
        setSelectedDestinations(selDestinations);
    }, [selDestinations])

    useEffect(() => {
        setSelectedRegions(selRegions);
    }, [selRegions])

    useEffect(() => {
        setSelectedResorts(selResorts);
    }, [selResorts])

    const selectOption = (destID) => {
        const sd = [];
        for(const x of selectedDestinations){
            sd.push(x);
        }

        let i = sd.indexOf(destID)

        if(i != -1){
            sd.splice(i, 1);
        }
        else{
            sd.push(destID) 
            setSearchValue('');
        }

        setSelectedDestinations(sd);

    }

    const selectRegion = (regionID) => {
        const sd = [];
        for(const x of selectedRegions){
            sd.push(x);
        }

        let i = sd.indexOf(regionID)

        if(i != -1){
            sd.splice(i, 1);
        }
        else{
            sd.push(regionID) 
            setSearchValue('');
        }

        setSelectedRegions(sd);
    }

    const selectResort = (resortID) => {
        const sd = [];
        for(const x of selectedResorts){
            sd.push(x);
        }

        let i = sd.indexOf(resortID)

        if(i != -1){
            sd.splice(i, 1);
        }
        else{
            sd.push(resortID) 
            setSearchValue('');
        }

        setSelectedResorts(sd);

    }



    
    let capturedDest = [];
    let destinations = [];
    for(const x of options){
        const seg = x.split('-');
        if(seg.length > 8){
            continue;
        }

        let t = capturedDest.indexOf(seg[6] > -1);

        if(capturedDest.indexOf(seg[6]) > -1){
            continue;
        }
        destinations.push({'id': Number(seg[6]), 'name': seg[7], 'type': 'c', 'hide': false, 'regions': []});
        capturedDest.push(seg[6]);
    }

    let capturedRegions = []
    for(const x of options){
        const seg = x.split('-');
        if(seg.length > 8){
            continue;
        }

        if(capturedRegions.indexOf(seg[4]) > -1){
            continue
        }

        for(const d of destinations){
            if(seg[6] == d.id){
                d.regions?.push({'id': Number(seg[4]), 'name': seg[5], 'type': 'g', 'hide': false, 'resorts': []})
                capturedRegions.push(seg[4]);
            }
        }
    }

    let capturedResorts = []
    for(const x of options){

        const seg = x.split('-');
        if(seg.length > 8){
            continue;
        }

        if(capturedResorts.indexOf(seg[2]) > -1){
            continue
        }

        for(const d of destinations){
            for(const g of d.regions){
                if(seg[4] == g.id){
                    g.resorts?.push({'id': Number(seg[2]), 'name': seg[3], 'type': 's', 'hide': false})
                    capturedResorts.push(seg[2]);
                }
            }
        }
    }

    const chkInputStyle = {height: '0px', width: '0px', display: 'none'};
    const spanSelected = <span style={{display: 'flex', width: '24px', height: '24px', color: 'white', alignItems: 'center', justifyContent: 'center'}}>
        <img src='/images/tick.svg' style={{color: 'white'}} height='20' width='20' />
        </span>


    let availableOptions = [];
    let selectedOptions = [];

    let strSelectedDests = [];
    let strSelectedRegions = [];
    let strSelectedResorts = [];

    for(let x of destinations){


        let selTick = null;
        let isSelected = false;
        if(selectedDestinations.indexOf(x.id) > -1){
            selTick = spanSelected
            isSelected = true;
            strSelectedDests.push(x.name);
        }

        if(isSelected){
            selectedOptions.push(<div className="destselector_con_option">
            <input onChange={() => {selectOption(x.id)}} style={chkInputStyle} type="checkbox" id={'chkd' + x.id} name={'chkd' + x.id} value={x.id} checked={isSelected} />
            <label className='destselector_chk' htmlFor={'chkd' + x.id}>{selTick}</label>
            <label className='destselector_lbl' htmlFor={'chkd' + x.id}>{x.name}</label>
            </div>)
        }
        else{
            if(searchValue != ''){
                const strName = x.name.toLowerCase();
    
                if(strName.indexOf(searchValue.toLowerCase().trim()) > -1){
                    availableOptions.push(<div className="destselector_con_option">
                    <input onChange={() => {selectOption(x.id)}} style={chkInputStyle} type="checkbox" id={'chkd' + x.id} name={'chkd' + x.id} value={x.id} checked={isSelected} />
                    <label className='destselector_chk' htmlFor={'chkd' + x.id}>{selTick}</label>
                    <label className='destselector_lbl' htmlFor={'chkd' + x.id}>{x.name}</label>
                    </div>)
                }
    
            }
            else{
                availableOptions.push(<div className="destselector_con_option">
                <input onChange={() => {selectOption(x.id)}} style={chkInputStyle} type="checkbox" id={'chkd' + x.id} name={'chkd' + x.id} value={x.id} checked={isSelected} />
                <label className='destselector_chk' htmlFor={'chkd' + x.id}>{selTick}</label>
                <label className='destselector_lbl' htmlFor={'chkd' + x.id}>{x.name}</label>
                </div>)
            }
        }
      


        for(const g of x.regions){

            let selTickRegion = null;
            let isSelectedRegion = false;
            if(selectedRegions.indexOf(g.id) > -1){
                selTickRegion = spanSelected
                isSelectedRegion = true;
                strSelectedDests.push(g.name);
            }

            if(isSelectedRegion){
                selectedOptions.push(<div className="destselector_con_option">
                <input onChange={() => {selectRegion(g.id)}} style={chkInputStyle} type="checkbox" id={'chkg' + g.id} name={'chkg' + g.id} value={g.id} checked={isSelectedRegion} />
                <label className='destselector_chk' htmlFor={'chkg' + g.id}>{selTickRegion}</label>
                <label className='destselector_lbl' htmlFor={'chkg' + g.id}>{g.name}</label>
                </div>)
            }
            else{
                if(searchValue != ''){
                    const strName = g.name.toLowerCase();
                    if(strName.indexOf(searchValue.toLowerCase().trim())  > -1){
                        
                        availableOptions.push(<div className="destselector_con_option">
                        <input onChange={() => {selectRegion(g.id)}} style={chkInputStyle} type="checkbox" id={'chkg' + g.id} name={'chkg' + g.id} value={g.id} checked={isSelectedRegion} />
                        <label className='destselector_chk' htmlFor={'chkg' + g.id}>{selTickRegion}</label>
                        <label className='destselector_lbl' htmlFor={'chkg' + g.id}>{g.name}</label>
                        </div>)
                    }
                }
            }
 

            for(const s of g.resorts){

                let selTickResort = null;
                let isSelectedResort = false;
                if(selectedResorts.indexOf(s.id) > -1){
                    selTickResort = spanSelected
                    isSelectedResort = true;
                    strSelectedDests.push(s.name);
                }

                if(isSelectedResort){
                    selectedOptions.push(<div className="destselector_con_option">
                    <input onChange={() => {selectResort(s.id)}} style={chkInputStyle} type="checkbox" id={'chkg' + s.id} name={'chkg' + s.id} value={s.id} checked={isSelectedResort} />
                    <label className='destselector_chk' htmlFor={'chkg' + s.id}>{selTickResort}</label>
                    <label className='destselector_lbl' htmlFor={'chkg' + s.id}>{s.name}</label>
                    </div>)
                }
                else{
                    if(searchValue != ''){
                        const strName = s.name.toLowerCase();
                        if(strName.indexOf(searchValue.toLowerCase().trim())  > -1){
                            
                            availableOptions.push(<div className="destselector_con_option">
                            <input onChange={() => {selectResort(s.id)}} style={chkInputStyle} type="checkbox" id={'chkg' + s.id} name={'chkg' + s.id} value={s.id} checked={isSelectedResort} />
                            <label className='destselector_chk' htmlFor={'chkg' + s.id}>{selTickResort}</label>
                            <label className='destselector_lbl' htmlFor={'chkg' + s.id}>{s.name}</label>
                            </div>)
                        }
                    }
                }
      
            }



        }




    }


    if(!isOpen){

        let placeHolder = 'Select Destinations'

        let strsd = strSelectedDests.join(',');
        let strsg = strSelectedRegions.join(',');
        let strss = strSelectedResorts.join(',');

        return(
            <div className="field">
            <div className="control">
                <div className={"destselector_con_search"}>
                    <input onChange={(e) => setSearchValue(e.currentTarget.value)}  onClick={() => setOpen()} placeholder={placeHolder}></input>
                </div>

                <div className="destselector_selected_options">
                    {strsd} {strsg} {strss}
                </div>

            </div>
          </div>
        ); 
    }

    if(options.length == 0){
        return(
            <div className="field">
            <div className="control">
                <div className={"destselector_con_search"}>
                    <input onChange={(e) => setSearchValue(e.currentTarget.value)}  onClick={() => setOpen()} placeholder="Search Destinations"></input>
                </div>
                <div className={"destselector_con_options"}>
                    Please wait...
                </div>
            </div>
          </div>
          );
    }

    return(
   
                <div className="field">
                <div className="control">
                    <div className={"destselector_con_search"}>
                        <input onChange={(e) => setSearchValue(e.currentTarget.value)} value={searchValue} onClick={() => setOpen()} placeholder="Search Destinations"></input>
                    </div>

                    <div className={"destselector_con_options"}>
                        {selectedOptions}
                        {availableOptions}
                    </div>

                </div>
            </div>
      

      );

}

export default DestinationSelector;