
import React, {useEffect, useState} from 'react';
import { Chart } from 'react-google-charts'
import {search_region_count_by_month} from '../../../service/search'

const RegionCountMonth = ({prod_type=null, duration=null, date_from=null, date_to=null, adults=null, children=null, rooms=null, year=null, token}) => {

    const [region_by_month, set_g_month_view] = useState(null);


    const  popd = (duration, date_from, date_to, adults, children, rooms, year, prod_type) => {

        const limit = 8;

        search_region_count_by_month(duration, date_from, date_to, adults, children, rooms, year, prod_type, token).then((d) => {
            if(d != null){
                let g_data = []
        
                const months = []
                for(const x of d){
                    const p = months.indexOf(x.month != -1)
                    if(months.indexOf(x.month) != -1){
                        continue
                    }
                    months.push(Number(x.month))
                }

                const regions = []
                const head = [{ type: "string", label: "Month" }]
                for(const x of d){
        
                    if(regions.length == limit){
                        break;
                    }
        
                    if(regions.indexOf(x.name) != -1){
                        continue
                    }
                    regions.push(x.name)
                    head.push(x.name)
                }
        
                g_data.push(head)
                
                months.sort(function(a, b) {
                    return a - b;
                });
        
                for(const m of months){
        
                    let vals = [m]
                    for(const g of regions){
                        let f = false;
                        for(const x of d){
                            if(x.month == m && x.name == g){
                                vals.push(x.count)
                                f = true;
                                break
                            }
                        }
                        if(!f){
                            vals.push(0)
                        }
                    }	
                    g_data.push(vals);
                }
                
                set_g_month_view(g_data);
            }
        });
    }
    

    useEffect(() =>{
        popd(duration, date_from, date_to, adults, children, rooms, year, prod_type);
    }, [])

    useEffect(() =>{
        popd(duration, date_from, date_to, adults, children, rooms, year, prod_type);
    }, [duration, adults, children, rooms, year, prod_type])    

    useEffect(() =>{
        if(date_from == null || date_to == null){
            return
        }
        popd(duration, date_from, date_to, adults, children, rooms, year, prod_type);

    }, [date_from, date_to])  


    return (<div>
        <Chart
            chartType="Line"
            width="700px"
            height="400px"
            data={region_by_month}
            options={{}}
        />
    </div>)

}

export default RegionCountMonth