import React, { useState, useEffect,  } from "react";
import {all_departure_points} from '../service/destination'
import Modal from 'react-modal';

const DeparturePointSelector = ({
  open=false,
  onChange,
  openChange,
  selPoints,
  token
}) => {



    const [isOpen, setIsOpen] = useState(false);

    const [selectedPoints, setSelectedPoints] = useState(selPoints);

    const [options, setOptions] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        all_departure_points(token).then((d) => {
            if(d == null){
                return;
            }
            if(d.all_departure_points.result == null){
                return;
            }
            setOptions(d.all_departure_points.result);
        });
    }, [])

    useEffect(() => {
        if(!isOpen){
            return;
        }
        openChange(isOpen);
    }, [isOpen])

    useEffect(() => {
        if(!open){
         setIsOpen(false);
         return
        }
        setIsOpen(true);
     }, [open])
 
     useEffect(() => {
        if(searchValue.length > 0){
         setIsOpen(true);
        }
      }, [searchValue])

      useEffect(() => {
        setSelectedPoints(selPoints);
     }, [selPoints])


     useEffect(() => {

        
        if(onChange){
            onChange(selectedPoints);
        }
  
      }, [selectedPoints])

     const setOpen = () => {
         if(!isOpen){
             setIsOpen(true);
             return
         }
         setIsOpen(false);
     }

     const selectOption = (depID) => {
        const sd = [];
        for(const x of selectedPoints){
            sd.push(x);
        }

        let i = sd.indexOf(depID)

        if(i != -1){
            sd.splice(i, 1);
        }
        else{
            sd.push(depID) 
            setSearchValue('');
        }

        setSelectedPoints(sd);

    }

    let points = [];
    for(const x of options){
        const seg = x.split('-');
        if(seg.length > 4){
            continue;
        }
        points.push({'id': Number(seg[0]), 'name': seg[1], 'code': seg[2]});
    }

    const chkInputStyle = {height: '0px', width: '0px', display: 'none'};
    const spanSelected = <span style={{display: 'flex', width: '24px', height: '24px', color: 'white', alignItems: 'center', justifyContent: 'center'}}>
        <img src='/images/tick.svg' style={{color: 'white'}} height='20' width='20' />
        </span>

    let availableOptions = [];
    let selectedOptions = [];
    let strSelectedPoints = [];

    for(let x of points){


        let selTick = null;
        let isSelected = false;
        if(selectedPoints.indexOf(x.id) > -1){
            selTick = spanSelected
            isSelected = true;
            strSelectedPoints.push(x.name);
        }

        if(isSelected){
            selectedOptions.push(<div className="destselector_con_option">
            <input onChange={() => {selectOption(x.id)}} style={chkInputStyle} type="checkbox" id={'chkdp' + x.id} name={'chkdp' + x.id} value={x.id} checked={isSelected} />
            <label className='destselector_chk' htmlFor={'chkdp' + x.id}>{selTick}</label>
            <label className='destselector_lbl' htmlFor={'chkdp' + x.id}>{x.name}</label>
            </div>)
        }
        else{
            if(searchValue != ''){
                const strName = x.name.toLowerCase();
                const strCode = x.code.toLowerCase();
    
                if(strName.indexOf(searchValue.toLowerCase().trim()) > -1 || searchValue.toLowerCase().trim() == strCode){
                    availableOptions.push(<div className="destselector_con_option">
                    <input onChange={() => {selectOption(x.id)}} style={chkInputStyle} type="checkbox" id={'chkdp' + x.id} name={'chkdp' + x.id} value={x.id} checked={isSelected} />
                    <label className='destselector_chk' htmlFor={'chkdp' + x.id}>{selTick}</label>
                    <label className='destselector_lbl' htmlFor={'chkdp' + x.id}>{x.name}</label>
                    </div>)
                }
    
            }
            else{
                availableOptions.push(<div className="destselector_con_option">
                <input onChange={() => {selectOption(x.id)}} style={chkInputStyle} type="checkbox" id={'chkdp' + x.id} name={'chkdp' + x.id} value={x.id} checked={isSelected} />
                <label className='destselector_chk' htmlFor={'chkdp' + x.id}>{selTick}</label>
                <label className='destselector_lbl' htmlFor={'chkdp' + x.id}>{x.name}</label>
                </div>)
            }
        }
    }

    if(!isOpen){

        let placeHolder = 'Select Departure Points'

        let strsd = strSelectedPoints.join(',');

        return(
            <div className="field">
            <div className="control">
                <div className={"destselector_con_search"}>
                    <input onChange={(e) => setSearchValue(e.currentTarget.value)}  onClick={() => setOpen()} placeholder={placeHolder}></input>
                </div>

                <div className="destselector_selected_options">
                    {strsd}
                </div>

            </div>
          </div>
        ); 
    }

    if(options.length == 0){
        return(
            <div className="field">
            <div className="control">
                <div className={"destselector_con_search"}>
                    <input onChange={(e) => setSearchValue(e.currentTarget.value)}  onClick={() => setOpen()} placeholder="Search Departure Points"></input>
                </div>
                <div className={"destselector_con_options"}>
                    Please wait...
                </div>
            </div>
          </div>
          );
    }

    return(<div className="field">
        <div className="control">
            <div className={"destselector_con_search"}>
                <input onChange={(e) => setSearchValue(e.currentTarget.value)} value={searchValue} onClick={() => setOpen()} placeholder="Search Destinations"></input>
            </div>

            <div className={"destselector_con_options"}>
                {selectedOptions}
                {availableOptions}
            </div>
        </div>
    </div>);

}

export default DeparturePointSelector;