import React, {useEffect, useState} from 'react';
import { Chart } from 'react-google-charts'
import {journey_progress_by_month} from '../../../service/journey'

const JourneyProgressMonth = ({prod_type=null, date_from=null, date_to=null, adults=null, children=null, rooms=null, token}) => {

	const [journey_prog, set_journey_prog] = useState(null);

    const get_step = (step_id) => {
        let lbl = ""
        if(step_id == 1){
            lbl = "Flight & room";
        }
        else if(step_id == 2){
            lbl = "Extras";
        }
        else if(step_id == 3){
            lbl = "Passengers";
        }
        else if(step_id == 4){
            lbl = "Payment";
        }
        else{
            lbl = "Confirmation";
        }
        return lbl;
    }

	const  popd = (date_from, date_to, adults, children, rooms, prod_type) => {
        const limit = 20;
		journey_progress_by_month(date_from, date_to, adults, children, rooms, prod_type, token).then((d) => {
            if(d != null){
				let g_data = []

				const months = []
				for(const x of d){

					if(months.length == limit){
						break;
					}

					const p = months.indexOf(x.month != -1)
					if(months.indexOf(x.month) != -1){
						continue
					}
					months.push(x.month)
				}				
				
				
				const steps = []
				const head = [{ type: "string", label: "Months" }]
				for(const x of d){

                    let s = get_step(x.step);
					if(steps.length == limit){
						break;
					}

					if(steps.indexOf(s) != -1){
						continue
					}
					steps.push(s)
					head.push(s)
				}

				g_data.push(head)
	
				for(const a of months){

					let vals = [a]
					for(const stp of steps){
						let f = false;
						for(const x of d){
                            let s = get_step(x.step);
							if(x.month == a && s == stp){
								vals.push(x.count)
								f = true;
								break
							}
						}
						if(!f){
							vals.push(0)
						}
					}	
					g_data.push(vals);
				}
				set_journey_prog(g_data);
			}
		});
	}

    useEffect(() =>{
        popd(date_from, date_to, adults, children, rooms, prod_type);
    }, [])

    useEffect(() =>{
    	popd(date_from, date_to, adults, children, rooms, prod_type);
    }, [adults, children, rooms, prod_type])    

    
    useEffect(() =>{
        if(date_from == null || date_to == null){
            return
        }
        popd(date_from, date_to, adults, children, rooms, prod_type);
    }, [date_from, date_to])




    return (<div>
        <Chart
        chartType="ComboChart"
        width="700px"
        height="400px"
        data={journey_prog}
        options={{seriesType: "bars", series: { 5: { type: "line" } }}}
        />
    </div>)

}

export default JourneyProgressMonth

