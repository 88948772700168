import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import {user_two_fac_auth} from '../../service/sub_user'

const Challenge = ({
    update_log_in,
    user_name,
    user_id
}) => {

    const [code, setCode] = useState('');
    const [error, setError] = useState(' ');

    useEffect(() =>{



    }, [])

    const challenge_code = async() => {

        let x = await user_two_fac_auth(user_name, user_id, code)

        if(x == null){
            setError(<><p style={{color:'red'}}>Could not verify your code. Code may have expired.</p>
            <br></br>
            <a href='/'>Return to login</a></>)
        }

        update_log_in(x);
    }


	return (
		<section>
			<div className='logIn' style={{display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
            <div style={{marginTop:'50px', alignSelf:'center', padding:'5px 5px 5px 5px'}}>

                <div style={{alignSelf:'center'}}>

                <p>Please check your email for your verification code</p>

                <div style={{display: 'inline-flex', width:'90px', paddingTop:'10px'}}>
                Verification Code
                </div>
                <div style={{display: 'inline-flex', paddingTop:'10px'}}>
                <input onChange={(e) => setCode(e.target.value)}></input>
                </div>
                </div>

                {error}
 

                <div style={{alignSelf:'center'}}>
                    <div style={{display: 'inline-flex', paddingTop:'10px'}}>
                    <button onClick={(e) => challenge_code()}>Submit</button>
                    </div>
                </div> 
            </div>
			</div>

		</section>
	);
};

export default Challenge;
