import React, {useEffect, useState} from 'react';
import { Chart } from 'react-google-charts'
import {accommodation_count_by_month} from '../../../service/accommodation'

const AccommodationHitsMonth = ({prod_type=null, destination_ids=null, region_ids=null, date_from=null, date_to=null, token}) => {

	const [acc_hit_view, set_acc_hit_view] = useState(null);

	const  popd = (destination_ids, region_ids, date_from, date_to, prod_type) => {
        const limit = 6;
		accommodation_count_by_month(destination_ids, region_ids, date_from, date_to, null,prod_type, token).then((d) => {
            if(d != null){
                let g_data = []
        
                const months = []
                for(const x of d){
                    const p = months.indexOf(x.month != -1)
                    if(months.indexOf(x.month) != -1){
                        continue
                    }
                    months.push(Number(x.month))
                }

                const accs = []
                const head = [{ type: "string", label: "Month" }]
                for(const x of d){
        
                    if(accs.length == limit){
                        break;
                    }
        
                    if(accs.indexOf(x.name) != -1){
                        continue
                    }
                    accs.push(x.name)
                    head.push(x.name)
                }
        
                g_data.push(head)
                
                months.sort(function(a, b) {
                    return a - b;
                });
        
                for(const m of months){
        
                    let vals = [m]
                    for(const g of accs){
                        let f = false;
                        for(const x of d){
                            if(x.month == m && x.name == g){
                                vals.push(x.count)
                                f = true;
                                break
                            }
                        }
                        if(!f){
                            vals.push(0)
                        }
                    }	
                    g_data.push(vals);
                }
                
                set_acc_hit_view(g_data);
            }
		});
	}

    useEffect(() =>{

        popd(destination_ids, region_ids, date_from, date_to, prod_type);


    }, [])

    useEffect(() =>{
        popd(destination_ids, region_ids, date_from, date_to, prod_type);
    }, [destination_ids, region_ids])    
    
    useEffect(() =>{
        if(date_from == null || date_to == null){
            return
        }
        popd(destination_ids, region_ids, date_from, date_to, prod_type);
    }, [date_from, date_to])

    useEffect(() =>{
        popd(destination_ids, region_ids, date_from, date_to, prod_type);
    }, [prod_type])

    

    return (<div>
        <Chart chartType="ColumnChart" 
        width="700px" 
        height="400px" 
        data={acc_hit_view} />
    </div>)

}

export default AccommodationHitsMonth

