import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import {add_margin, update_margin, margin} from '../../service/margin'
import '../margin_add/style.css';

const MarginAdd = ({user_token, set_menu, authorize}) => {
    const [margin_d, setMarginD] = useState(null);
    const [description, setDescription] = useState("");
    const [margin_type, setMarginType] = useState(0);
    const [prod_type, setProdType] = useState(0);
    const [order, setOrder] = useState(0);
    const [amount, setAmount] = useState(0);
    const [per_pax, setPerPax] = useState(0);
    const [btn_lbl, setBtnLbl] = useState('Add');
    const [ready, setReady] = useState(false)
    useEffect(() => {

        authorize(1);

        let x = queryString.parse(document.location.search);

        const lnks = [{'url': '/', 'name': 'Home'}, {'url': '/margins', 'name': 'Margins'}]
		
		set_menu(lnks)

        if(x.mid == null){
            setReady(true);
            return
        }
        let f = "id description margin_type product_type per_pax order amount";

        margin(Number(x.mid),f, user_token).then((r) =>{

            if(r == null){   
                setReady(true);
				return
			}
			if(r.errors != null){
				if(r.errors.length > 0){
					alert(r.errors[0].message)
					return
				}
				alert("Unexpected Error Contact HWT support...")
				return
			}
			if(r.margin.error != null){
				alert(r.margin.error.message)
				return
			}	

            if(r.margin.result == null){
				return
			}

            setMarginD(r.margin.result);
            setDescription(r.margin.result.description);
            setMarginType(r.margin.result.margin_type);
            setProdType(r.margin.result.product_type);
            setAmount(r.margin.result.amount);
            setPerPax(r.margin.result.per_pax)
            setBtnLbl('Update');
            setReady(true);
          
        });
    }, [])

    const perPax = (chk, value) => {
        if(chk && value == 0){
            setPerPax(0);
        }
        else{
            setPerPax(1);
        }
    }

    const addupdate_margin = async () => {

        let desc = document.getElementById("txt_desc").value;
        let type = document.getElementById("ddl_type").value;
        let prod_type = document.getElementById("ddl_prodtype").value;
        let ord = order
        let amount = document.getElementById("txt_amount").value;
        //make graph all and add this sucker

        if(margin_d == null){
            let new_marg = await add_margin(desc, type, amount, per_pax, prod_type, "id", user_token)
            if(new_marg == null){
                alert("Failed to add margin. Contact HWT support");
                return
            }
        }
        else{
            let new_marg = await update_margin(margin_d.id, desc, type, amount, per_pax, prod_type, ord, "id", user_token)
            if(new_marg == null){
                alert("Failed to update margin. Contact HWT support");
                return
            }

        }


        window.location.replace("/margins");


    }

    if(!ready){
        return(<div className='input_form'>Loading form...</div>)
    }




    let type_ops = [{"value": 0, "text": "Monetary (£)"}, {"value": 1, "text": "Percentage (%)"}]
    let type_ops_x = [];
    for(let x of type_ops){
        if(margin_type == x.value){
            type_ops_x.push(<option selected={true} value={x.value}>{x.text}</option>);
        }
        else{
            type_ops_x.push(<option value={x.value}>{x.text}</option>);
        }
    }  
    
    let prod_type_ops = [{"value": 0, "text": "Accommodation"}, {"value": 1, "text": "Flights"}, {"value": 2, "text": "Flight Extras"}, {"value": 3, "text": "Transfers"}
    , {"value": 4, "text": "Vehicle Hire"}, {"value": 5, "text": "Package"}]
    let prod_type_ops_x = [];

    for(let x of prod_type_ops){
        if(prod_type == x.value){
            prod_type_ops_x.push(<option selected={true} value={x.value}>{x.text}</option>);
        }
        else{
            prod_type_ops_x.push(<option value={x.value}>{x.text}</option>);
        }
    }

    let descriptionx = <div className='input_section'>
        <div className='input_label_section'>
            <label>Description</label>
        </div>
        <div className='input_field_section large'>
            <input id='txt_desc' onChange={(d) => setDescription(d.value)} value={description}></input>
        </div>
    </div>

    let mtype = <div className='input_section'>
          <div className='input_label_section'>
            <label>Margin Type</label>
        </div>
        <div className='input_field_section'>
            <select id='ddl_type' onChange={(d) => setMarginType(d.value)}>
                {type_ops_x}
            </select>
        </div>
    </div>

    let ptype = <div className='input_section'>
    <div className='input_label_section'>
    <label>Product Type</label>
    </div>
    <div className='input_field_section'>
    <select id='ddl_prodtype'>
        {prod_type_ops_x}
    </select>
    </div>
    </div>

    // let orderx = <div className='input_section'>
    // <div className='input_label_section'>
    //     <label>Order</label>
    // </div>
    // <div className='input_field_section medium'>
    //     <input id='txt_ord' onChange={(d) => setOrder(d.value)} type='number' value={order}></input>
    // </div>
    // </div>

    let amt = <div className='input_section'>
    <div className='input_label_section'>
        <label>Amount</label>
    </div>
    <div className='input_field_section medium'>
        <input id='txt_amount' onChange={(d) => setAmount(d.value)} type='number' value={amount}></input>
    </div>
    </div>

    let noCheck = true;
    let yesCheck = false;
    if(per_pax == 1){
        noCheck = false;
        yesCheck = true;
    }

    let amt_per_pax = <div className='input_section'>
    <div className='input_label_section'>
        <label>Per person (e.g if Yes amount = Amount * number of pax)</label>
    </div>
    <div className='input_field_section'>
        <input type="radio" id="optPerPaxNo" onChange={(d) => perPax(d.target.checked, d.target.value)} checked={noCheck} name="optPerPax" value="0"></input>
        <label for="optPerPaxNo">No</label>
        <input type="radio" id="optPerPaxYes" onChange={(d) => perPax(d.target.checked, d.target.value)} checked={yesCheck} name="optPerPax" value="1"></input>
        <label for="optPerPaxYes">Yes</label>
    </div>
    </div>

    let btn = <div className='btn_section'>
        <button onClick={() => {addupdate_margin()}}>{btn_lbl}</button>
    </div>

    return(<div className='input_form'>
        {descriptionx}
        {mtype}
        {ptype}
        {amt}
        {amt_per_pax}
        {btn}
    </div>);


};

export default MarginAdd