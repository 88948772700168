import { GraphQLClient, gql } from 'graphql-request';

export const mode = 'PROD'; // 'PROD';
//export const loc = 'https://engine.holidaywebtech.co.uk';
//export const loc = 'https://eng.se.dev.holidaywebtech.co.uk';
export const loc = process.env.REACT_APP_HTTP_URL
export const imgloc = '/images/acc/';


const j = 500;
export const toFixed = (num, fixed) => {
	num = Number(num);
	return num.toFixed(fixed);
};

export const graph2 = async(query, token) => {
	let graphQLClient = new GraphQLClient(process.env.REACT_APP_GRAPHQL_URL, {
		headers: {
		  Authorization: `Bearer ${token}`,
		},
	});
	return await graphQLClient.request(query);
}

export const graph_vars = async(query, vars, token) => {
	let graphQLClient = new GraphQLClient(process.env.REACT_APP_GRAPHQL_URL, {
		headers: {
		  Authorization: `Bearer ${token}`,
		},
	});
	return await graphQLClient.request(query, vars);
}

export const graph_vars_str = (vars) => {

	let vparams = ""
    let qparams = ""
    let j_params = {}
    if(vars.length != 0){
        let v = []
        let p = []
        for(let x of vars){
            v.push(x["param_var_name"] + ": " + x["param_type"]);
            p.push(x["param_name"] + ": " + x["param_var_name"]);
            j_params[x["param_name"]] = x["value"]
        }
        vparams = `(${v.join(" ")})`
        qparams = `(${p.join(" ")})`
    }
	return [vparams, qparams, j_params]
}

export const graph = (query, vars, callback, token) =>{
	
	let e = process.env.REACT_APP_GRAPHQL_URL
	fetch(e, {method: 'POST', 
	headers: 
	{
		'Authorization': 'Bearer ' + token,
		'Content-Type': 'application/json',
		'Accept': 'application/json'
	},
	body: JSON.stringify({
		query
		})
	}).then(r => callback(r.json()))
}


export const querySt = (window, key) => {
	var url = window.location.href;
	var KeysValues = window.location.href
		.slice(window.location.href.indexOf('?') + 1)
		.split('&');
	for (var i = 0; i < KeysValues.length; i++) {
		var KeyValue = KeysValues[i];
		var io = KeyValue.indexOf('=') + 1;
		var kname = KeyValue.split('=')[0];
		//KeyValue = KeysValues[i].split("=");
		if (kname == key) {
			var value = KeyValue.substring(io);
			return value;
		}
	}
	return '';
};

export const api = (url, data, method = 'GET', callback, errcallback, token=null, location = null) => {

	var config = {
		headers: {
			'Content-Type': 'application/json',
		},
		method,
		body: method.toLowerCase() == 'post' ? JSON.stringify(data) : null,
	};

	if(token != null){
		config = {
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'application/json',
			},
			method,
			body: method.toLowerCase() == 'post' ? JSON.stringify(data) : null,
		};
	}
	let l = loc;
	if(location != null){
		l = location;
	}
	fetch(l + url, config)
		.then((res) => {
			if (!res.ok) {
				//Try Log error in DB
				try {
					console.log(res.statusText);
					//DB error log call here
					//res.json().then(err => this.LogError(err));
				} catch (error) {
					//Failed to log into DB console log it and maybe do something else
					console.log(res.statusText);
				}
				errcallback();
			} else {
				callback(res);
			}
		})
		.catch((err) => (errcallback != null ? errcallback(err) : null));
};

export const cookie =(name,value,isJson,exdays) =>{

	if(isJson)
	{
		value = JSON.stringify(value);
	}

	var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
	var expires = "expires="+ d.toUTCString();
	document.cookie = name + "=" + value + ";" + expires + "; path=/";
}

export const expire_cookie = (name) => {
	var d = new Date();
	var expires = "expires="+ d.toUTCString();
	document.cookie = name + "=-1;" + expires + "; path=/";
}

export const getCookie = (cname,isJson) => {
	var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {

			try {
				if(isJson)
				{
					return JSON.parse(c.substring(name.length, c.length));
				}
				else{
					return c.substring(name.length, c.length);
				}
			}
			catch(err){
				console.log(err);
			}

        }
    }
    return null;
}
