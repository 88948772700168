import {api} from '../utils/shared'

export const journey_progress = async(date_from=null, date_to=null, adults=null, children=null, rooms=null, product_type=null, token) => {
    const pd = {
        "accommodation_id": null,
        "date_from": date_from,
        "date_to": date_to,
        "adults": adults,
        "children": children,
        "rooms": rooms,
        "type": product_type
    }

    return new Promise(async (resolve, reject) => {
        await api(
            "/journey_progress_count/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const journey_progress_by_month = async(date_from=null, date_to=null, adults=null, children=null, rooms=null, product_type=null, token) => {
    const pd = {
        "accommodation_id": null,
        "date_from": date_from,
        "date_to": date_to,
        "adults": adults,
        "children": children,
        "rooms": rooms,
        "type": product_type
    }

    return new Promise(async (resolve, reject) => {
        await api(
            "/journey_progress_by_month/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const journey_progress_by_accommodation = async(date_from=null, date_to=null, adults=null, children=null, rooms=null, product_type=null, token) => {
    const pd = {
        "accommodation_id": null,
        "date_from": date_from,
        "date_to": date_to,
        "adults": adults,
        "children": children,
        "rooms": rooms,
        "type": product_type
    }

    return new Promise(async (resolve, reject) => {
        await api(
            "/journey_progress_by_accommodation/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}