import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import {update_deposit, add_deposit, deposit} from '../../service/deposit'
import '../margin_add/style.css';

const DepositAdd = ({user_token, set_menu, authorize}) => {
    const [discount_d, setDiscountD] = useState(null);
    const [description, setDescription] = useState("");
    const [deposit_type, setDepositType] = useState(0);
    const [prod_type, setProdType] = useState(0);
    const [order, setOrder] = useState(0);
    const [amount, setAmount] = useState(0);
    const [max_price, setMaxPrice] = useState(0);
    const [per_pax, setPerPax] = useState(0);
    const [btn_lbl, setBtnLbl] = useState('Add');
    const [ready, setReady] = useState(false)

    useEffect(() => {

        authorize(1);
        let x = queryString.parse(document.location.search);

        const lnks = [{'url': '/', 'name': 'Home'}, {'url': '/deposits', 'name': 'Deposits'}]
		
		set_menu(lnks)

        if(x.did == null){
            setReady(true);
            return
        }
        let f = "id description deposit_type product_type max_price per_pax amount";

        deposit(Number(x.did),f, user_token).then((r) =>{

            if(r == null){
                setReady(true);
				return
			}
			if(r.errors != null){
				if(r.errors.length > 0){
					alert(r.errors[0].message)
					return
				}
				alert("Unexpected Error Contact HWT support...")
				return
			}
			if(r.deposit.error != null){
				alert(r.deposits.error.message)
				return
			}	

            if(r.deposit.result == null){
				return
			}

            setDiscountD(r.deposit.result);
            setDescription(r.deposit.result.description);
            setDepositType(r.deposit.result.deposit_type);
            setProdType(r.deposit.result.product_type);
            setAmount(r.deposit.result.amount);
            setPerPax(r.deposit.result.per_pax);

            let max_price = r.deposit.result.max_price;
            if(max_price != -1){
                setMaxPrice(max_price);
            }
       
            setBtnLbl('Update');
            setReady(true);
        });
    }, [])

    const perPax = (chk, value) => {
        if(chk && value == 0){
            setPerPax(0);
        }
        else{
            setPerPax(1);
        }
    }

    const addupdate_deposit = async () => {

        let desc = document.getElementById("txt_desc").value;
        let type = document.getElementById("ddl_type").value;
        let prod_type = document.getElementById("ddl_prodtype").value;
        let ord = order
        let amount = Number(document.getElementById("txt_amount").value);
        let x = document.querySelector('input[name="optPerPax"]:checked').value;
        let max_price =  Number(document.getElementById("txt_maxamount").value);

        if(max_price == 0){
            max_price = -1;
        }

        //make graph all and add this sucker
        if(discount_d == null){
            let new_discount = await add_deposit(desc, type, amount, max_price, per_pax, prod_type, "id", user_token)
            if(new_discount == null){
                alert("Failed to add deposit. Contact HWT support");
                return
            }
        }
        else{
            let new_discount = await update_deposit(discount_d.id, desc, type, amount, max_price, per_pax, prod_type, "id", user_token)
            if(new_discount == null){
                alert("Failed to update deposit. Contact HWT support");
                return
            }
        }
        window.location.replace("/deposits");
    }

    
    if(!ready){
        return(<div className='input_form'>Loading form...</div>)
    }


    let type_ops = [{"value": 0, "text": "Monetary (£)"}, {"value": 1, "text": "Percentage (%)"}]
    let type_ops_x = [];
    for(let x of type_ops){
        if(deposit_type == x.value){
            type_ops_x.push(<option selected={true} value={x.value}>{x.text}</option>);
        }
        else{
            type_ops_x.push(<option value={x.value}>{x.text}</option>);
        }
    }  
    
    let prod_type_ops = [{"value": 0, "text": "Accommodation"}, {"value": 1, "text": "Flights"}, {"value": 2, "text": "Flight Extras"}, {"value": 3, "text": "Transfers"}
    , {"value": 4, "text": "Vehicle Hire"}, {"value": 5, "text": "Package"}]
    let prod_type_ops_x = [];

    for(let x of prod_type_ops){
        if(prod_type == x.value){
            prod_type_ops_x.push(<option selected={true} value={x.value}>{x.text}</option>);
        }
        else{
            prod_type_ops_x.push(<option value={x.value}>{x.text}</option>);
        }
    }

    let descriptionx = <div className='input_section'>
        <div className='input_label_section'>
            <label>Description</label>
        </div>
        <div className='input_field_section large'>
            <input id='txt_desc' onChange={(d) => setDescription(d.value)} value={description}></input>
        </div>
    </div>

    let mtype = <div className='input_section'>
          <div className='input_label_section'>
            <label>deposit Type</label>
        </div>
        <div className='input_field_section'>
            <select id='ddl_type' onChange={(d) => setDepositType(d.value)}>
                {type_ops_x}
            </select>
        </div>
    </div>

    let ptype = <div className='input_section'>
    <div className='input_label_section'>
    <label>Product Type</label>
    </div>
    <div className='input_field_section'>
    <select id='ddl_prodtype'>
        {prod_type_ops_x}
    </select>
    </div>
    </div>

    // let orderx = <div className='input_section'>
    // <div className='input_label_section'>
    //     <label>Order</label>
    // </div>
    // <div className='input_field_section medium'>
    //     <input id='txt_ord' onChange={(d) => setOrder(d.value)} type='number' value={order}></input>
    // </div>
    // </div>

    let amt = <div className='input_section'>
    <div className='input_label_section'>
        <label>Amount</label>
    </div>
    <div className='input_field_section medium'>
        <input id='txt_amount' onChange={(d) => setAmount(d.value)} type='number' value={amount}></input>
    </div>
    </div>

    let maxprice = <div className='input_section'>
    <div className='input_label_section'>
        <label>Maximum Cost</label>
    </div>
    <div className='input_field_section medium'>
        <input id='txt_maxamount' onChange={(d) => setMaxPrice(d.value)} type='number' value={max_price}></input>
    </div>
    </div>

    let noCheck = true;
    let yesCheck = false;
    if(per_pax == 1){
        noCheck = false;
        yesCheck = true;
    }

    let amt_per_pax = <div className='input_section'>
    <div className='input_label_section'>
        <label>Per person (e.g if Yes deposit is calculated based on number of pax)</label>
    </div>
    <div className='input_field_section'>
        <input type="radio" id="optUseTotalNo" onChange={(d) => perPax(d.target.checked, d.target.value)} checked={noCheck} name="optPerPax" value="0"></input>
        <label for="optPerPaxNo">No</label>
        <input type="radio" id="optUseTotalyes" onChange={(d) => perPax(d.target.checked, d.target.value)} checked={yesCheck} name="optPerPax" value="1"></input>
        <label for="optPerPaxYes">Yes</label>
    </div>
    </div>



    let btn = <div className='btn_section'>
        <button onClick={() => {addupdate_deposit()}}>{btn_lbl}</button>
    </div>

    return(<div className='input_form'>
        {descriptionx}
        {mtype}
        {ptype}
        {amt}
        {maxprice}
        {amt_per_pax}
        {btn}
    </div>);


};

export default DepositAdd