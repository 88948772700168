import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import {product_feed, add_product_feed, update_product_feed, product_feed_schemas, product_feed_query } from '../../service/product_feed'
import DestinationSelector from '../../components/destination_selector'
import DeparturePointSelector from '../../components/departure_point_selector'
import YMDPicker from '../../components/ymd_picker'

import '../product_feed_add/style.css';

const ProductFeed = ({user_token, set_menu, authorize}) => {


    const [feed_id, setFeedID] = useState(-1);

    const [btn_lbl, setBtnLbl] = useState('Add');
    const [ready, setReady] = useState(false)

    const [destination_show, setDestinationShow] = useState(false);
    const [dep_show, setDepShow] = useState(false);

    const [cheapest_offers, setCheapestOffers] = useState(true);

    const [offers_by_month, setOffersByMonth] = useState(false);

    const [departure_date, setDepartureDate] = useState(null);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [selected_schema, setSelectedSchema] = useState(null);

    const [adults, setAdults] = useState(2);
    const [children, setChildren] = useState(0);
    const [duration, setDuration] = useState(7);

    const [destinations, setDestinations] = useState([]);
    const [regions, setRegions] = useState([]);
    const [resorts, setResorts] = useState([]);
    const [departures, setDeparturePoints] = useState([])

    const [trip_type, setTripType] = useState(0);

    const [email_address, setEmailAddress] = useState('');

    const [enabled, setEnabled] = useState(true);

    const [price_per_person, setPricePerPerson] = useState(true);

    const [schema_options, setSchemaOptions] = useState(null)

 


    const hide_fields = (e) => {

        if(e.target == e.currentTarget){
            setDestinationShow(false);
            setDepShow(false);
        }
    }

    useEffect(() => {

     
        authorize(4);
        const lnks = [{'url': '/', 'name': 'Home'}, {'url': '/product_feeds', 'name': 'Product Feeds'}]
		set_menu(lnks)

        let sf = 'id name created_by created'
        product_feed_schemas(sf, user_token).then((so) => {

            if(so.product_feed_schemas.result != null)
            setSchemaOptions(so.product_feed_schemas.result)

            let x = queryString.parse(document.location.search);
            if(x.pfid == null){
                setReady(true);
                return
            }
            let f = "id name description enabled schema_id";
            product_feed(Number(x.pfid),f, user_token).then((xr) =>{

                if(xr.product_feed.result == null){
                    setReady(true);
                    return
                }

                setBtnLbl("Update");
           
                const d  = xr.product_feed.result;
                setFeedID(d.id);
                setName(d.name);
                setDescription(d.description);
                setSelectedSchema(d.schema_id);
                setEnabled(d.enabled);

                const fq = 'destinations regions resorts departing duration ratings trip_ratings adults children de_duplicate client_acc_list departure_date departure_date_type trip_type send price_per_person';
                product_feed_query(Number(x.pfid), fq, user_token).then((qry) => {

                    if(qry.product_feed_query.result == null){
                        setReady(true);
                        return
                    }
                    const pfq = qry.product_feed_query.result;

                    setDestinations(pfq.destinations);
                    setRegions(pfq.regions);
                    setResorts(pfq.resorts);
                    setDeparturePoints(pfq.departing);

                    setAdults(pfq.adults);
                    setChildren(pfq.children);
                    setDuration(pfq.duration);

                    if(pfq.departure_date != null){
                        setDepartureDate(pfq.departure_date);
                    }     
                    
                    if(pfq.departure_date_type == 1){
                        setOffersByMonth(true);
                    }
                  
                    if(pfq.de_duplicate == 0){
                        setCheapestOffers(false);
                    }

                    if(pfq.send.length > 0){
                        setEmailAddress(pfq.send[0]);
                    }

                    if(pfq.price_per_person == 0){
                        setPricePerPerson(false);
                    }

                    setTripType(pfq.trip_type);

                    setReady(true);
                });

                return

            });
    
        })
    }, [])

    const ondest_show = (x) => {
        if(x){
            setDepShow(false);
        }

        setDestinationShow(x);
    }

    const ondep_show = (x) => {
        if(x){
            setDestinationShow(false);
        }

        setDepShow(x);
    }

    const ondestination_change = (d, g, s) => {
        setDestinations(d);
        setRegions(g);
        setResorts(s);
    }

    const ondep_change = (dp) => {
        setDeparturePoints(dp);
    }

    const ondepdate_change = (v) => {
        if(v == null){
            setDepartureDate(null);
        }
        setDepartureDate(v);
    }




    const addupdate_prod_feed = async () => {

        let co = 0;
        if(cheapest_offers){
            co = 1;
        }

        let obm = 0;
        if(offers_by_month){
            obm = 1; 
        }

        let str_dest = '[' + destinations.join(',') + ']';
        let str_region = '[' + regions.join(',') + ']';
        let str_resort = '[' + resorts.join(',') + ']';
        let str_departing = '[' + departures.join(',') + ']';
    

        let pp = 1;
        if(!price_per_person){
            pp = 0;
        }

        if(feed_id == -1){

            let selschem = selected_schema;
            if(selschem == null){
                selschem = schema_options[0].id;
            }

            let created = await add_product_feed(name, str_dest, str_region, str_resort, str_departing, adults, children, duration, selschem, email_address, 
                description, [], departure_date, obm, co, trip_type, pp, 'id', user_token)
            if(created == null){
                alert("Failed to create product feed. Contact HWT support");
                return
            }
        }
        else{

            let ebl = 1;
            if(!enabled){
                ebl = 0;
            }

      

            let updated =  await update_product_feed(feed_id, name, str_dest, str_region, str_resort, str_departing, adults, children, duration, selected_schema, email_address, 
                description, [], departure_date, obm, co, trip_type, ebl, pp, 'id', user_token);

            if(updated == null){
                alert("Failed to update product feed. Contact HWT support");
                return
            }
        }

        window.location.replace("/product_feeds");
    }

    if(!ready){
        return(<div className='input_form'>Loading form...</div>)
    }



    let showDay = true;

    if(offers_by_month == true){
        showDay = false;
    }


    // let amt = <div className='input_section'>
    // <div className='input_label_section'>
    //     <label>Amount</label>
    // </div>
    // <div className='input_field_section medium'>
    //     <input id='txt_amount' onChange={(d) => setAmount(d.value)} type='number' value={amount}></input>
    // </div>
    // </div>

    let nameInput = <div className='input_section'>
    <div className='input_label_section'>
        <label>Name</label>
    </div>
    <div className='input_field_section medium'>
        <input id='txtName' onChange={(e) => setName(e.target.value)} value={name}></input>
    </div>
    </div>

    let destSelector = <div className='input_section'>
    <div className='input_label_section'>
        <label>Destinations</label>
    </div>
    <div className='input_field_section medium'>
        <DestinationSelector open={destination_show} selDestinations={destinations} selRegions={regions} selResorts={resorts} openChange={(x) => ondest_show(x)} onChange={(d, g ,s) => ondestination_change(d, g ,s)} token={user_token}></DestinationSelector>
    </div>
    </div>

    let depSelector = <div className='input_section'>
    <div className='input_label_section'>
        <label>Departing</label>
    </div>
    <div className='input_field_section medium'>
        <DeparturePointSelector open={dep_show} selPoints={departures} openChange={(x) => ondep_show(x)} onChange={(dp) => ondep_change(dp)} token={user_token}></DeparturePointSelector>
    </div>
    </div>

    let depDateSelector = <div className='input_section'>
    <div className='input_label_section'>
        <label>Departure Date</label>
    </div>
    <div className='input_field_section medium'>
        <YMDPicker id='sel_dc_from' key='sel_bk_from_k' initalValue={departure_date} show_day_selector={showDay} prev_years={0} future_years={1} onChange={(value) => ondepdate_change(value)}></YMDPicker>
    </div>
    </div>

    let monthSelector = null;
    if(departure_date != null){
        monthSelector = <div className='input_section'>
        <div className='input_label_section'>
            <label>Offers by month</label>
        </div>
        <div className='input_field_section medium'>
            <input onChange={(e) => {setOffersByMonth(e.target.checked)}} checked={offers_by_month} type="checkbox" id={'chkCheapOffers'} />
        </div>
        </div>
    }

    const adults_ops = [];
    for(let i = 1; i < 5; i ++){
        let selected = false;
        if(i == adults){
            selected = true;
        }
        adults_ops.push(<option selected={selected}>{i}</option>)
    }

    let adultsSelector = <div className='input_section'>
    <div className='input_label_section'>
        <label>Adults</label>
    </div>
    <div className='input_field_section medium'>
        <select onChange={(e) => setAdults(e.target.value)}>
            {adults_ops}
        </select>
    </div>
    </div>

    const child_ops = [];
    for(let i = 0; i < 5; i ++){
        let selected = false;
        if(i == children){
            selected = true;
        }
        child_ops.push(<option selected={selected}>{i}</option>)
    }

    let childrenSelector = <div className='input_section'>
    <div className='input_label_section'>
        <label>Children</label>
    </div>
    <div className='input_field_section medium'>
        <select onChange={(e) => setChildren(e.target.value)}>
            {child_ops}
        </select>
    </div>
    </div>

    const a_durations = [3, 7 ,10, 14];
    const duration_ops = []
    for(const d of a_durations){
        let selected = false;
        if(d == duration){
            selected = true;
        }
        duration_ops.push(<option selected={selected}>{d}</option>)
    }

    let durationSelector = <div className='input_section'>
    <div className='input_label_section'>
        <label>Duration</label>
    </div>
    <div className='input_field_section medium'>
        <select onChange={(e) => setDuration(e.target.value)}>
            {duration_ops}
        </select>
    </div>
    </div>


    let typeSelector = <div className='input_section'>
    <div className='input_label_section'>
        <label>Holiday Type</label>
    </div>
    <div className='input_field_section medium'>
        <select onChange={(e) => setTripType(e.target.value)} value={trip_type} defaultValue={2}>
            <option value="0">Beach</option>
            <option value="1">City</option>
        </select>
    </div>
    </div>


    let schemaSelector = null;
    if(schema_options != null){

        let ops = [];
        for(const so of schema_options){
            let selected = false;
            if(selected_schema != null){
                if(selected_schema == so.id){
                    selected = true;
                }
            }
            ops.push(<option selected={selected} value={so.id}>{so.name}</option>)
        }
        schemaSelector = <div className='input_section'>
        <div className='input_label_section'>
            <label>Feed Format</label>
        </div>
        <div className='input_field_section medium'>
            <select onChange={(e) => setSelectedSchema(e.target.value)}>
                {ops}
            </select>
        </div>
        </div>
    }



    let emailInput = <div className='input_section'>
    <div className='input_label_section'>
        <label>Email Address</label>
    </div>
    <div className='input_field_section medium'>
        <input id='txtEmail' onChange={(e) => setEmailAddress(e.target.value)}  value={email_address}></input>
    </div>
    </div>

    let descInput = <div className='input_section'>
    <div className='input_label_section'>
        <label>Description</label>
    </div>
    <div className='input_field_section medium'>
        <textarea id='txtDesc' onChange={(e) => setDescription(e.target.value)} value={description} cols='30' rows='5'></textarea>
    </div>
    </div>



    let duplicateSelector = <div className='input_section'>
    <div className='input_label_section'>
        <label>Cheapeast offers</label>
    </div>
    <div className='input_field_section medium'>
        <input onChange={(e) => {setCheapestOffers(e.target.checked)}} checked={cheapest_offers} type="checkbox" id={'chkCheapOffers'} />
    </div>
    </div>

    let pricePerPersonSelector = <div className='input_section'>
    <div className='input_label_section'>
        <label>Price Per Person</label>
    </div>
    <div className='input_field_section medium'>
        <input onChange={(e) => {setPricePerPerson(e.target.checked)}} checked={price_per_person} type="checkbox" id={'chkPricePerPerson'} />
    </div>
    </div>

    let enabledSelector = <div className='input_section'>
    <div className='input_label_section'>
        <label>Enabled</label>
    </div>
    <div className='input_field_section medium'>
        <input onChange={(e) => {setEnabled(e.target.checked)}} checked={enabled} type="checkbox" id={'chkEnabled'} />
    </div>
    </div>

    let btn = <div className='btn_section'>
        <button onClick={() => {addupdate_prod_feed()}}>{btn_lbl}</button>
    </div>

    return(
    <div className='closer' onClick={(e) => hide_fields(e)} >
    <div className='input_form'>
    
       {nameInput}
      {destSelector}
      {depSelector}
      {adultsSelector}
      {childrenSelector}
      {durationSelector}
      {typeSelector}
      {depDateSelector}
      {monthSelector}
      {duplicateSelector}
      {descInput}
      {schemaSelector}
      {emailInput}     
      {pricePerPersonSelector}
      {enabledSelector}
      {btn}
     
    </div>
    </div>);

};

export default ProductFeed