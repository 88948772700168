import React, {useEffect, useState} from 'react';
import {discounts, discount_remove} from '../../service/discount'
import './style.css';


const Discounts = ({user_token, set_menu, authorize}) => {

    const [d_view, setDataView] = useState(null);

    useEffect(() =>{

		authorize(1);

		const lnks = [{'url': '/', 'name': 'Home'}, {'url': '/discounts/add', 'name': 'Create Discount'}]
		
		set_menu(lnks)

		let f = "id description discount_type product_type"
		discounts(f, user_token).then((r) =>{
			if(r == null){
				return
			}	
			if(r.errors != null){
					if(r.errors.length > 0){
						alert(r.errors[0].message)
						return
					}
					alert("Unexpected Error Contact HWT support...")
					return
			}
			if(r.discounts.error != null){
				alert(r.discounts.error.message)
				return
			}
			setDataView(r.discounts.result)
		}
		);
    }, [])	
	
	const remove = async (id) => {
		let f = "id description discount_type product_type"

		const r = await discount_remove(id, f, user_token);
		if(r.errors != null){
			if(r.errors.length > 0){
				alert(r.errors[0].message)
				return
			}
			alert("Unexpected Error Contact HWT support...")
			return
		}
		if(r.discount_remove.error != null){
			alert(r.discount_remove.error.message)
			return
		}
		setDataView(r.discount_remove.result)
	}

    let tbl_view = null;
	if(d_view != null){

        if(d_view.length == 0){
            return (<div>
                <div className='nothing_here'>
                <p>Nothing to see here <br></br>
                   It looks like you have no discount rules setup. Click <a href='/discounts/add'>here</a> to create a rule.</p>
                </div>
            </div>)
        }

		let rows = []
		for(let x of d_view){

			let prod_type = '';
			if(x.product_type == 0){
				prod_type = "Accommodation";
			}
            else if(x.product_type == 1){
                prod_type = "Flights";
            }
            else if(x.product_type == 2){
                prod_type = "Flight Extras";
            }
            else if(x.product_type == 3){
                prod_type = "Transfers";
            }
            else if(x.product_type == 4){
                prod_type = "Vehicle Hire";
            }

            let dtype = '';
            if(x.discount_type == 0){
                dtype = '£'
            }
            else{
                dtype = '%'
            }


			rows.push(<tr>
				<td>
					{x.description}
				</td>
				<td>
					{dtype}
				</td>
				<td>
					{prod_type}
				</td>
				<td>
					<a href={'/discounts/add?did=' + x.id}>Edit</a>
				</td>
                <td>
					<a style={{cursor:"pointer", textDecoration:'underline', color:'red'}} onClick={() => remove(x.id)}>Remove</a>
				</td>
			</tr>)
		}

		tbl_view = <table>
				<thead>
					<td>
						Description
					</td>
					<td>
						Type 
					</td>
					<td>
						Product
					</td>
					<td></td>
                    <td></td>
				</thead>
			<tbody>
				{rows}
			</tbody>
		</table>
	}

    return(<div className='rule_tbl'>
        {tbl_view}
    </div>);


};

export default Discounts;