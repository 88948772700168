import {api, graph2, graph_vars, graph_vars_str} from '../utils/shared'

export const baskets = async(token, fields, vars=[]) => {

    let p = graph_vars_str(vars)

    let vparams = p[0]
    let qparams = p[1]
    let j_params = p[2]

    let q = `query ${vparams} {
            sessions ${qparams}
            {
                result
                    {
                        ${fields}
                    }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`

    let x = await graph_vars(q, j_params, token)
    return x
}

export const basket_from_reference = async(token, fields, reference) => {

    let vars = []
    vars.push({"param_var_name": "$reference", "param_name": "reference", "param_type": "String!", "value": reference})
    let p = graph_vars_str(vars)

    let vparams = p[0]
    let qparams = p[1]
    let j_params = p[2]

    let q = `query ${vparams} {
            session_from_reference ${qparams}
            {
                result
                    {
                        ${fields}
                    }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`

    let x = await graph_vars(q, j_params, token)
    return x
}

export const session_rooms_reference = async(reference, fields, token) => {
    let q = `{
        session_rooms_reference(reference: "${reference}")
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const session_flight_reference = async(product_id, reference, fields, token) => {
    let q = `{
        session_flight_reference(product_id: ${product_id}, reference: "${reference}")
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const session_flight_extras_reference = async(reference, fields, token) => {
    let q = `{
        session_flight_extras_reference(reference: "${reference}")
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const session_transfer_reference = async(product_id, reference, fields, token) => {
    let q = `{
        session_transfer_reference(product_id: ${product_id}, reference: "${reference}")
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const session_package_reference = async(reference, fields, token) => {
    let q = `{
        session_package_reference(reference: "${reference}")
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}



export const session_count = async(date_from=null, date_to=null, type=null, token) => {
    return new Promise(async (resolve, reject) => {

        const pd = {
            "date_from": date_from,
            "date_to": date_to,
            "type": type
        }

        await api(
            "/session_count/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const session_count_by_month = async(date_from=null, date_to=null, type=null, token) => {
    return new Promise(async (resolve, reject) => {

        const pd = {
            "date_from": date_from,
            "date_to": date_to,
            "type": type
        }

        await api(
            "/session_count_by_month/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const session_average_cost = async(date_from=null, date_to=null, type=null, token) => {
    return new Promise(async (resolve, reject) => {

        const pd = {
            "date_from": date_from,
            "date_to": date_to,
            "type": type
        }

        await api(
            "/session_average_cost/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const session_total_cost = async(date_from=null, date_to=null, type=null, token) => {
    return new Promise(async (resolve, reject) => {

        const pd = {
            "date_from": date_from,
            "date_to": date_to,
            "type": type
        }

        await api(
            "/session_total_cost/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}