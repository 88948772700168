import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ServerDataProvider } from "./state/serverDataContext";

const dataCache = window.__SERVER_DATA__;
const _window = window;

let root = createRoot(document.getElementById("root"));
root.render(<App _window={_window} history={_window.history} />);

// ReactDOM.render((
//     <BrowserRouter>
//       <Switch>
//             <Route exact path="/" component={Home} />
//             <Route path="/about" component={About} />
//       </Switch>
//     </BrowserRouter>
// ), document.getElementById('root'))

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
