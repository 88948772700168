import {api, graph2, graph_vars, test, test2} from '../utils/shared'


export const margins = async(fields, token) => {
    let q = `{margins {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const margin = async(margin_id, fields, token) => {
    let q = `{margin(margin_id: ${margin_id}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const add_margin = async(description, margin_type, amount, per_pax, product_type, fields, token) => {
    let q = `mutation {margin_add(description: "${description}", margin_type: ${margin_type}, amount: ${amount}, per_pax: ${per_pax}, product_type: ${product_type}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const update_margin = async(margin_id, description, margin_type, amount, per_pax, product_type, order, fields, token) => {
    let q = `mutation {margin_update(margin_id: ${margin_id}, description: "${description}", margin_type: ${margin_type}, amount: ${amount}, per_pax: ${per_pax}, product_type: ${product_type}, order: ${order}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const margin_remove = async(margin_id, fields, token) => {
    let q = `mutation {margin_remove(margin_id: ${margin_id}) {
		result {
			${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}