import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import {promo_update, promo_add, promo} from '../../service/promo'
import '../margin_add/style.css';
import YMDPicker from '../../components/ymd_picker'

const PromoAdd = ({user_token, set_menu, authorize}) => {
    const [promo_d, setPromoD] = useState(null);
    const [promo_code, setCode] = useState("");
    const [promo_type, setPromoType] = useState(0);
    const [expire, setExpire] = useState(null);
    const [minimum_cost, setMinCost] = useState(null);
    const [amount, setAmount] = useState(0);
    const [cookie_verification, setCookieVerification] = useState(null);
    const [number_of_uses, setNumberOfUses] = useState(null);
    const [departure_from, setDepartureFrom] = useState(null);
    const [departure_to, setDepartureTo] = useState(null);

    useEffect(() => {
        
        authorize(1);

        let x = queryString.parse(document.location.search);

        const lnks = [{'url': '/', 'name': 'Home'}, {'url': '/promos', 'name': 'Promotional Codes'}]
		set_menu(lnks)

        if(x.pid == null){
            let jh = 500;
            return
        }
        let f = "id code verification_cookie expires amount type minimum_cost departure_from departure_to number_of_uses";

        promo(Number(x.pid),f, user_token).then((r) =>{

            if(r == null){
				return
			}
			if(r.errors != null){
				if(r.errors.length > 0){
					alert(r.errors[0].message)
					return
				}
				alert("Unexpected Error Contact HWT support...")
				return
			}
			if(r.promotional_code.error != null){
				alert(r.promotional_codes.error.message)
				return
			}	

            if(r.promotional_code.result == null){
				return
			}

            setPromoD(r.promotional_code.result);
            setCode(r.promotional_code.result.code);
            setPromoType(r.promotional_code.result.type);
            setExpire(r.promotional_code.result.expires);
            setAmount(r.promotional_code.result.amount);
            setMinCost(r.promotional_code.result.minimum_cost);
            setCookieVerification(r.promotional_code.result.verification_cookie);
            setNumberOfUses(r.promotional_code.result.number_of_uses);
            setDepartureFrom(r.promotional_code.result.departure_from);
            setDepartureTo(r.promotional_code.result.departure_to);
            
          
        });
    }, [])

    const change_expire = (value) => {
        setExpire(value);
    }

    const change_dep_from = (value) => {
        setDepartureFrom(value);
    }

    const change_dep_to = (value) => {
        setDepartureTo(value);
    }

    const addupdate_discount = async () => {

        if(promo_code == null){
            alert("Code required")
            return;
        }
        if(expire == null){
            alert("Expire date required")
            return;
        }

        let str_dep = "";
        let str_to = "";
        if(departure_from != null && departure_to != null){
            str_dep = departure_from;
            str_to = departure_to;
        }

        let min_cost = -1;
        if(minimum_cost != null){
            min_cost = minimum_cost;
        }

        let num_of_use = -1;
        if(number_of_uses != null){
            num_of_use = number_of_uses;
        }


        //make graph all and add this sucker
        if(promo_d == null){
            let new_discount = await promo_add(promo_code, cookie_verification, expire, amount, promo_type, str_dep, str_to, min_cost, num_of_use, "id", user_token)
            if(new_discount == null){
                alert("Failed to add promotional code. Contact HWT support");
                return
            }
        }
        else{
            let new_discount = await promo_update(promo_d.id, promo_code, cookie_verification, expire, amount, promo_type, str_dep, str_to, min_cost, num_of_use, "id", user_token)
            if(new_discount == null){
                alert("Failed to update promotional code. Contact HWT support");
                return
            }
        }
  
        
        window.location.replace("/promos");

    }

    let type_ops = [{"value": 0, "text": "Monetary (£)"}, {"value": 1, "text": "Percentage (%)"}]
    let type_ops_x = [];
    for(let x of type_ops){
        if(promo_type == x.value){
            type_ops_x.push(<option selected={true} value={x.value}>{x.text}</option>);
        }
        else{
            type_ops_x.push(<option value={x.value}>{x.text}</option>);
        }
    }  
    
    let codeSec = <div className='input_section'>
        <div className='input_label_section'>
            <label>Code</label>
        </div>
        <div className='input_field_section'>
            <input id='txt_code' onChange={(d) => setCode(d.target.value)} value={promo_code}></input>
        </div>
    </div>

    let pTypeSec = <div className='input_section'>
          <div className='input_label_section'>
            <label>Promotional Code Type</label>
        </div>
        <div className='input_field_section'>
            <select id='ddl_type' onChange={(d) => setPromoType(d.target.value)}>
                {type_ops_x}
            </select>
        </div>
    </div>

    let amt = <div className='input_section'>
    <div className='input_label_section'>
        <label>Amount</label>
    </div>
    <div className='input_field_section medium'>
        <input id='txt_amount' onChange={(d) => setAmount(d.target.value)} type='number' value={amount}></input>
    </div>
    </div>   
     
    let minCostSec = <div className='input_section'>
    <div className='input_label_section'>
        <label>Minimum Cost</label>
    </div>
    <div className='input_field_section medium'>
        <input id='txt_min_cost' onChange={(d) => setMinCost(d.target.value)} type='number' value={minimum_cost}></input>
    </div>
    </div>

    let numberOfusesSec = <div className='input_section'>
    <div className='input_label_section'>
        <label>Number of uses</label>
    </div>
    <div className='input_field_section medium'>
        <input id='txt_num_use' onChange={(d) => setNumberOfUses(d.target.value)} type='number' value={number_of_uses}></input>
    </div>
    </div>

    let expireSec = <div className='input_section'>
    <div className='input_label_section'>
        <label>Expires</label>
    </div>
    <div className='input_field_section medium'>
        <YMDPicker id='sel_expire' key='sel_expire' initalValue={expire} future_years={5} onChange={(value) => change_expire(value)}></YMDPicker> 
    </div>
    </div>

    let depFromTo = <div className='input_section'>
    <div className='input_label_section'>
        <label>Departure Date From / To </label>
    </div>
    <div className='input_field_section medium'>
        <YMDPicker id='sel_dep_from' key='sel_dep_from' initalValue={departure_from} future_years={5} onChange={(value) => change_dep_from(value)}></YMDPicker>
        <div>To</div>
        <YMDPicker id='sel_dep_to' key='sel_dep_to' initalValue={departure_to} future_years={5} onChange={(value) => change_dep_to(value)}></YMDPicker> 
    </div>
    </div>

    let cookieSec = <div className='input_section'>
          <div className='input_label_section'>
            <label>Cookie Verfication</label>
        </div>
        <div className='input_field_section'>
            <input id='txt_cookie_verificaiton' onChange={(d) => setCookieVerification(d.target.value)} value={cookie_verification}></input>
        </div>
    </div>



    let btn = <div className='btn_section'>
        <button onClick={() => {addupdate_discount()}}>Add</button>
    </div>



    return(<div className='input_form'>
        {codeSec}
        {pTypeSec}
        {amt}
        {expireSec}
        {depFromTo}
        {minCostSec}
        {numberOfusesSec}
        {cookieSec}
        {btn}
    </div>);


};

export default PromoAdd