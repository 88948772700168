
import React, {useEffect, useState} from 'react';
import {search_total_view_count} from '../../../service/search'

const SearchCountBlock = ({prod_type=null, date_from=null, date_to=null, title=null, sub_title=null, token}) => {

    const [count_v, set_d_count] = useState(null);

    const  popd = (date_from, date_to, prod_type) => {
        const limit = 8;
        search_total_view_count(date_from, date_to, prod_type, token).then((d) => {
            if(d != null){
                set_d_count(d)
            }
        });
    }
    

    let cTitle = 'Search Views';
    if(title != null){
        cTitle = title;
    }

    let cSubTitle = 'Users on search page';
    if(sub_title != null){
        cSubTitle = sub_title;
    }


    useEffect(() =>{
        popd(date_from, date_to, prod_type);
    }, [])

    useEffect(() =>{
        if(date_from == null || date_to == null){
            return
        }
        popd(date_from, date_to, prod_type);

    }, [date_from, date_to])  

    useEffect(() =>{
        
        popd(date_from, date_to, prod_type);

    }, [prod_type])  
    

    if(count_v == null){
        return;
    }
    const formatted = Number(count_v).toLocaleString('en-US');

    return (<div className='countBlock'>
        <div className='countBlockTitle'>
            {cTitle}
        </div>
        <div className='countBlockContent'>
            <div className='info'>{formatted}</div>
            <div className='info infosmall'>{cSubTitle}</div>
        </div>
    </div>)

}

export default SearchCountBlock
