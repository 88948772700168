
import React, {useEffect, useState} from 'react';
import { Chart } from 'react-google-charts'
import {search_resort_by_departure_point_count} from '../../../service/search'

const ResortCountDeparturePoint = ({prod_type=null, duration=null, departing_from=null, departing_to=null, date_from=null, date_to=null, adults=null, children=null, rooms=null, token}) => {

	const [d_v, set_dv] = useState(null);

    const  popd = (duration, departing_from, departing_to, date_from, date_to, adults, children, rooms, prod_type) => {

        const limit = 5;
		search_resort_by_departure_point_count(duration, departing_from, departing_to, date_from, date_to, adults, children, rooms, prod_type, token).then((d) => {
			if(d != null){
				let g_data = []

				const airports = []
				const airport_limit = 5;
				for(const x of d){

					if(airports.length == limit){
						break;
					}

					const p = airports.indexOf(x.departure_point != -1)
					if(airports.indexOf(x.departure_point) != -1){
						continue
					}
					airports.push(x.departure_point)
				}				
				
				const resorts = []
				const head = [{ type: "string", label: "Departure points" }]
				for(const x of d){

					if(resorts.length == limit){
						break;
					}

					if(resorts.indexOf(x.resort) != -1){
						continue
					}
					resorts.push(x.resort)
					head.push(x.resort)
				}

				g_data.push(head)
	
				for(const a of airports){

					let vals = [a]
					for(const dst of resorts){
						let f = false;
						for(const x of d){
							if(x.departure_point == a && x.resort == dst){
								vals.push(x.count)
								f = true;
								break
							}
						}
						if(!f){
							vals.push(0)
						}
					}	
					g_data.push(vals);
				}

				set_dv(g_data);

			}
		});

	}

    useEffect(() =>{
        popd(duration, departing_from, departing_to, date_from, date_to, adults, children, rooms, prod_type);
    }, [])

    useEffect(() =>{
        popd(duration, departing_from, departing_to, date_from, date_to, adults, children, rooms, prod_type);
    }, [duration, adults, children, rooms, prod_type])    

    useEffect(() =>{
        if(date_from == null || date_to == null){
            return
        }
        popd(duration, departing_from, departing_to, date_from, date_to, adults, children, rooms, prod_type);

    }, [date_from, date_to])    


    return (<div>
        <Chart
        chartType="ComboChart"
        width="700px"
        height="400px"
        data={d_v}
        options={{seriesType: "bars", series: { 5: { type: "line" } }}}
        />
    </div>)

}

export default ResortCountDeparturePoint