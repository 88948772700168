import {api, graph2, graph_vars, test, test2} from '../utils/shared'


export const discounts = async(fields, token) => {
    let q = `{discounts {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const discount = async(discount_id, fields, token) => {
    let q = `{discount(discount_id: ${discount_id}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const add_discount = async(description, discount_type, amount, per_pax, product_type, fields, token) => {
    let q = `mutation {discount_add(description: "${description}", discount_type: ${discount_type}, amount: ${amount}, per_pax: ${per_pax}, product_type: ${product_type}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const update_discount = async(discount_id, description, discount_type, amount, per_pax, product_type, order, fields, token) => {
    let q = `mutation {discount_update(discount_id: ${discount_id}, description: "${description}", discount_type: ${discount_type}, amount: ${amount}, per_pax: ${per_pax}, product_type: ${product_type}, order: ${order}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const discount_remove = async(discount_id, fields, token) => {
    let q = `mutation {discount_remove(discount_id: ${discount_id}) {
		result {
			${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}