
import React, {useEffect, useState} from 'react';
import { Chart } from 'react-google-charts'
import {booking_avg_cost_by_product_date} from '../../../service/booking'

const BookingCostProdDate = ({prod_type=null, duration=null, date_from=null, date_to=null, adults=null, children=null, token}) => {

    const [dest_by_month, set_g_month_view] = useState(null);


    const  popd = (duration, date_from, date_to, adults, children, prod_type) => {

        const limit = 8;

        booking_avg_cost_by_product_date(duration, date_from, date_to, adults, children, prod_type, token).then((d) => {
            if(d != null){
                let g_data = []
        
                const months = []
                for(const x of d){
                    const str_my = x.month + ' ' + x.year;

                    if(months.indexOf(str_my) != -1){
                        continue
                    }
                    months.push(str_my)
                }

                const regions = []
                const head = [{ type: "string", label: "Date" }]
                head.push("Average cost")
                
                g_data.push(head)
                        
                for(const x of d){

                    const str_my = x.month + ' ' + x.year;

                    let vals = [str_my, x.avg_cost]
                    
                    g_data.push(vals);
                }
                
                set_g_month_view(g_data);
            }
        });
    }
    


    useEffect(() =>{
        popd(duration, date_from, date_to, adults, children, prod_type);
    }, [])

    useEffect(() =>{
        popd(duration, date_from, date_to, adults, children, prod_type);
    }, [duration, adults, children])    

    useEffect(() =>{
        if(date_from == null || date_to == null){
            return
        }
        popd(duration, date_from, date_to, adults, children, prod_type);

    }, [date_from, date_to])    

    useEffect(() =>{
        popd(duration, date_from, date_to, adults, children, prod_type);
    }, [prod_type])    

    return (<div>
        <Chart
            chartType="Line"
            width="700px"
            height="400px"
            data={dest_by_month}
            options={{}}
        />
    </div>)

}

export default BookingCostProdDate
