import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import {booking, booking_flight, booking_flight_extras, booking_rooms, booking_transfer, 
	booking_payment_plan, add_booking_note, booking_update_confirmed, booking_package, booking_logs, booking_update_hidden} from '../../service/booking'
import {room_prod_table, flight_prod_table, transfer_prod_table, flight_extra_prod_table, package_prod_table} from '../../service/product'
import {cookie, getCookie, expire_cookie} from '../../utils/shared'

import { accommodation } from '../../service/accommodation'
import '../booking_view/style.css';
import dayjs from 'dayjs';

const BookingView = ({user_token, authorize}) => {

	
	const [booking_id, setBookingID] = useState(null);
    const [booking_d, setBookingData] = useState(null);
	
	const [booking_notes, setBookingNotes] = useState([]);

	const [booking_flight_d, setBookingFlight] = useState(null);
	const [booking_flight_extras_d, setBookingFlightExtras] = useState(null);

	const [booking_room_d, setBookingRoom] = useState(null);
	const [accommodation_d, setAccommodation] = useState(null);

	const [booking_package_d, setBookingPackage] = useState(null);

	const [booking_transfers_d, setBookingTransfers] = useState(null);
	const [booking_vehicle_d, setBookingVehicle] = useState(null);

	const [booking_payment_plan_d, setPaymentPlan] = useState(null);

	const [note, setNote] = useState('')
	const [processing, setProcessing] = useState(true)

    useEffect(() =>{

		authorize(3);

		let f = `id hidden session_reference date_of_booking customer {id first_name second_name email_address address post_code contact_telephone} outstanding_balance 
		payments {id amount auth_code part_card_number date_of_payment, success} people{ id title first_name second_name gender age date_of_birth }
		notes{id user_id user_name note created} products{id product_type{id} description price} reference cost status confirmed important_information payment_option duration`

		//page load. get the query string booking id
		let queryStrings = queryString.parse(window.location.search)
		let bid = Number(queryStrings.bid);

		//Execute the get booking graph call
		booking(bid, f, user_token).then((r) =>{
			if(r == null){
				return
			}
			if(r.errors != null){
				if(r.errors.length > 0){
					alert(r.errors[0].message)
					return
				}
				alert("Unexpected Error Contact HWT support...")
				return
			}
			if(r.booking.error != null){
				alert(r.booking.error.message)
				return
			}

			//update the booking data section (pretty much the page)
			setBookingData(r.booking.result);
			// hide loader
			setProcessing(false);
			// set booking ID var
			setBookingID(bid)

			if(r.booking.result.payment_option == 2){
				//Pay monthly 
				let bppf = 'id booking_id payment_day departure_Date outstanding_balance confirmed instalments{date_due amount}';
				booking_payment_plan(bid, bppf, user_token).then((rp) => {

					if(rp == null){
						return
					}
					if(rp.booking_payment_plan.result == null){
						return
					}
					setPaymentPlan(rp.booking_payment_plan.result);
				})
			}
		});
    }, [])

	useEffect(() =>{
		if(booking_d == null){
			return
		}
		get_room_prod();
		get_flight_prod();
		get_flight_extra_prod();
		get_transfer_prod();
		get_pak_prod();

		// for(let p of booking_d.products){
		// 	if(p.product_type.id == 0){
		// 		get_room_prod();
		// 	}
		// 	if(p.product_type.id == 1){
		// 		get_flight_prod();
		// 	}
		// 	if(p.product_type.id == 2){
		// 		get_flight_extra_prod();
		// 	}
		// 	if(p.product_type.id == 3){
		// 		get_transfer_prod();
		// 	}
		// }
		setBookingNotes(booking_d.notes);
	}, [booking_d])

	useEffect(() => {

		if(booking_room_d == null){
			return;
		}

		if(booking_room_d.length == 0){
			return;
		}

		let f = `name address lat long resort {name regions {name destinations {name}}}`

		accommodation(booking_room_d[0].accommodation_id, f, user_token).then((r) => {
			if(r == null){
				return
			}
			if(r.errors != null){
				if(r.errors.length > 0){
					alert(r.errors[0].message)
					return
				}
				alert("Unexpected Error Contact HWT support...")
				return
			}
			if(r.accommodation.error != null){
				alert(r.accommodation.error.message)
				return
			}
			setAccommodation(r.accommodation.result)

		})

	}, [booking_room_d])


	const get_flight_prod = () => {

		let f = `id number return_number provider_id price provider_price original_price margin discount booking_component{reference success} departure_airport{id code} 
		arrival_airport{id code} airline departing arrival return_departing return_arrival`

		for(let bp of booking_d.products){
			if(bp.product_type.id == 1){
				booking_flight(bp.id, booking_id, f ,user_token).then((r) =>{
	
					if(r == null){
						return
					}
					if(r.errors != null){
						if(r.errors.length > 0){
							alert(r.errors[0].message)
							return
						}
						alert("Unexpected Error Contact HWT support...")
						return
					}
					if(r.booking_flight.error != null){
						alert(r.booking_flight.error.message)
						return
					}
					setBookingFlight(r.booking_flight.result)
				});
			}
		}
	}

	const get_flight_extra_prod= () => {

		let f = `id description provider_id price provider_price original_price margin discount`

		booking_flight_extras(booking_id, f ,user_token).then((r) =>{

			if(r == null){
				return
			}
			if(r.errors != null){
				if(r.errors.length > 0){
					alert(r.errors[0].message)
					return
				}
				alert("Unexpected Error Contact HWT support...")
				return
			}
			if(r.booking_flight_extras.error != null){
				alert(r.booking_flight_extras.error.message)
				return
			}
			if(r.booking_flight_extras.result.length == 0){
				return
			}

			setBookingFlightExtras(r.booking_flight_extras.result)
		});
	}

	const get_logs = () => {
		if(booking_d == null){
			return;
		}
		let file_name =  booking_d.id + '_logs.zip';
		booking_logs(booking_d.session_reference, booking_d.id, user_token, file_name);
	}

	const get_pak_prod= () => {

		let f = `id
		flight {
			outbound_number
			inbound_number
			departure_point {
				name
				code
			}
			arrival_airport {
				name
				code
			}
			outbound_departure
			outbound_arrival
			inbound_departure
			inbound_arrival
			bag_count
		}
		accommodation {
			name
			address
			resort{
				name
				regions{
					name
					destinations{
						name
					}
				}
			}
		}
		rooms {
			provider_id
			board_code
			description
			board_description
			price
			margin
			discount
			provider_price
			booking_component {
				reference
				success
			}
			room_number
		}`

		booking_package(booking_id, f ,user_token).then((r) =>{

			if(r == null){
				return
			}
			if(r.errors != null){
				if(r.errors.length > 0){
					alert(r.errors[0].message)
					return
				}
				alert("Unexpected Error Contact HWT support...")
				return
			}
			if(r.booking_package.error != null){
				alert(r.booking_package.error.message)
				return
			}
			setBookingPackage(r.booking_package.result)
		});
	}

	const get_room_prod= () => {

		let f = `id accommodation_id description board board_description room_code booking_component{reference success} provider_id 
		price provider_price original_price margin discount start_date`

		booking_rooms(booking_id, f ,user_token).then((r) =>{

			if(r == null){
				return
			}
			if(r.errors != null){
				if(r.errors.length > 0){
					alert(r.errors[0].message)
					return
				}
				alert("Unexpected Error Contact HWT support...")
				return
			}
			if(r.booking_rooms.error != null){
				alert(r.booking_rooms.error.message)
				return
			}
			setBookingRoom(r.booking_rooms.result)
		});
	}

	const get_transfer_prod= () => {

		let f = `id vehicle_type description booking_component{reference success} provider_id price provider_price original_price margin discount`

		for(let bp of booking_d.products){
			if(bp.product_type.id == 3){
				booking_transfer(bp.id, booking_id, f ,user_token).then((r) =>{
	
					if(r == null){
						return
					}
					if(r.errors != null){
						if(r.errors.length > 0){
							alert(r.errors[0].message)
							return
						}
						alert("Unexpected Error Contact HWT support...")
						return
					}
					if(r.booking_transfer.error != null){
						alert(r.booking_transfer.error.message)
						return
					}
					setBookingTransfers(r.booking_transfer.result)
				});
			}
		}
	}

	const add_note = () => {

		//only need the notes
		let f = `notes{id note user_name created}`

		//show loader
		setProcessing(true);
		//execute graph add note call 
		add_booking_note(booking_id, note, f, user_token).then((r) =>{

			if(r == null){
				return
			}
			if(r.errors != null){
				if(r.errors.length > 0){
					if(r.errors[0].message == "403"){
						alert("You do not have permission to perform this action.")
					}
				}
				alert("Unexpected Error Contact HWT support...")

				setProcessing(false);
				return
			}
			if(r.booking_add_note.error != null){
				if(r.booking_add_note.error.message == "403"){
					alert("You do not have permission to perform this action.")
				}
				setProcessing(false);
				return
			}

			//Update booking note section on page with updated notes
			setBookingNotes(r.booking_add_note.result.notes)
			setProcessing(false);
			//Reset text box to empty string.
			setNote('')
		})
	}

	const set_booking_conf = async (conf) => {
		let queryStrings = queryString.parse(window.location.search)
		let bid = Number(queryStrings.bid);
		let c = 0;
		if(conf){
			c = 1;
		}
		await booking_update_confirmed(bid, c, user_token).then((r) =>{

			if(r == null){
				return
			}
			if(r.errors != null){
				alert("Unexpected Error Contact HWT support...")
				return
			}
			if(r.booking_update_confirmed.error != null){
				if(r.booking_update_confirmed.error.message == "403"){
					alert("You do not have permission to perform this action.")
				}
				return
			}
			window.location = window.location;
		});
	}

	const set_booking_hide = async (hide) => {
		let queryStrings = queryString.parse(window.location.search)
		let bid = Number(queryStrings.bid);
		let h = 0;
		if(hide){
			h = 1;
		}
		await booking_update_hidden(bid, h, user_token).then((r) =>{

			if(r == null){
				return
			}
			if(r.errors != null){
				alert("Unexpected Error Contact HWT support...")
				return
			}
			if(r.booking_update_hidden.error != null){
				if(r.booking_update_hidden.error.message == "403"){
					alert("You do not have permission to perform this action.")
				}
				return
			}
			window.location = window.location;
		});
	
	}

	const const_section = (product) => {
		let costs = []
		costs.push(<tr>
			<td>Margin</td>
			<td>{product.margin}</td>
			</tr>);
			costs.push(<tr>
				<td>Provider cost</td>
				<td>{product.provider_price}</td>
				</tr>);
			costs.push(<tr>
				<td>Original cost</td>
				<td>{product.original_price}</td>
				</tr>);
			costs.push(<tr>
				<td>Discount</td>
				<td>{product.discount}</td>
				</tr>);
			costs.push(<tr>
				<td>Cost</td>
				<td>{product.price}</td>
				</tr>);
		return costs;
	}

	// const get_notes = (booking_id) => {
	// 	notes(booking_id, props.user_token).then((nr) => {
	// 		setBookingNotes(nr)
	// 	});
	// }

	const extra_sec = (e, i) => {

		if(e.QuantityRequired > 0){
			i.push(<tr>
				<td>Code</td>
				<td>{e.Code}</td>
			</tr>)
			i.push(<tr>
				<td>Description</td>
				<td>{e.Description}</td>
			</tr>)
			i.push(<tr>
				<td>Qty required</td>
				<td>{e.QuantityRequired}</td>
			</tr>)
			if(e.Provider_Price != null){
				i.push(<tr>
					<td>Provider cost</td>
					<td>{e.Provider_Price}</td>
				</tr>)
			}
			if(e.Margin != null){
				i.push(<tr>
					<td>Margin</td>
					<td>{e.Margin}</td>
				</tr>)		
			}
			if(e.Original_Price != null){
				i.push(<tr>
					<td>Original cost</td>
					<td>{e.Original_Price}</td>
				</tr>)	
			}
			if(e.Discount != null){
				i.push(<tr>
					<td>Discount</td>
					<td>{e.Discount}</td>
				</tr>)	
			}
			if(e.Price != null){

				i.push(<tr>
					<td>Item cost</td>
					<td>{e.Price}</td>
				</tr>)	

				i.push(<tr>
					<td>Total cost</td>
					<td>{e.Price * e.QuantityRequired}</td>
				</tr>)
			}
		}

		return i;
	}
	
	if(booking_d == null){
		return
	}

	let tbl_view_booking = [];
	let tbl_view_payment = null;

	let tbl_view_flights =  null;
	let tbl_view_flight_extra = null;
	let tbl_view_acc = null;
	let tbl_view_transfer = null;
	let tbl_view_package = null;
	let tbl_total_cost = null;

	let customer_rows = []
	let customer_counter = 1;
	let lead_customer = booking_d.customer;

	for(let p of booking_d.people){

		let dob = dayjs(p.date_of_birth, 'YYYY-MM-DD HH:mm');
		dob = dob.format('DD/MM/YY');


		customer_rows.push(<tr>
			<td className='title_row' colspan='2'>Customer {customer_counter}</td>
		</tr>)
		customer_rows.push(<tr>
			<td>Name</td>
			<td style={{textTransform:'capitalize'}}>{p.title} {p.first_name} {p.second_name}</td>
		</tr>)
		customer_rows.push(<tr>
			<td>Date Of Birth</td>
			<td>{dob}</td>
		</tr>)

		if(lead_customer.first_name == p.first_name && lead_customer.second_name == p.second_name){
			customer_rows.push(<tr>
				<td>Address</td>
				<td>{lead_customer.address}<br></br>{lead_customer.post_code}</td>
			</tr>)
			customer_rows.push(<tr>
				<td>Contact Number</td>
				<td>{lead_customer.contact_telephone}</td>
			</tr>)
			customer_rows.push(<tr>
				<td>Email Address</td>
				<td>{lead_customer.email_address}</td>
			</tr>)
		}

		customer_counter++;
	}

	let booking_date = dayjs(booking_d.date_of_booking, 'YYYY-MM-DD HH:mm');
	booking_date = booking_date.format('DD MMMM YYYY HH:mm');

	let payment_method = 'Total';

	if(booking_d.payment_option == 1){
		payment_method = 'Deposit';
	}
	else if(booking_d.payment_option == 2){
		payment_method = 'Payment Plan';
	}

	let book_dur = null;
	if(booking_d.duration != 0){
		book_dur = 	<tr>
		<td>Duration</td>
		<td>{booking_d.duration}</td>
	</tr>
	}

	tbl_view_booking = <table>
		<tbody>
			<tr>
				<td>HWT Booking reference</td>
				<td>{booking_d.id}</td>
			</tr>
			<tr>
				<td>Client Booking reference</td>
				<td>{booking_d.reference}</td>
			</tr>
			<tr>
				<td>Date of booking</td>
				<td>{booking_date}</td>
			</tr>	
			{book_dur}	
			<tr>
				<td>Payment Method</td>
				<td>{payment_method}</td>
			</tr>
			<tr>
				<td>Total cost</td>
				<td>{booking_d.cost}</td>
			</tr>
			{customer_rows}
		</tbody>
	</table>

	let paymentSections = [];
	for(let p of booking_d.payments){
		let payment_rows = []
		let pd = dayjs(p.date_of_payment, 'YYYY-MM-DD HH:mm');
		pd = pd.format('DD MMMM YYYY HH:mm');

		let paySuccess = 'No';
		if(p.success == 1){
			paySuccess = 'Yes';

		}
		payment_rows.push(<tr>
			<td>Payment Date</td>
			<td>{pd}</td>
		</tr>)
		payment_rows.push(<tr>
			<td>Card Number</td>
			<td>{p.part_card_number}</td>
		</tr>)
		payment_rows.push(<tr>
			<td>Amount</td>
			<td>{p.amount}</td>
		</tr>)
		payment_rows.push(<tr>
			<td>Success</td>
			<td>{paySuccess}</td>
		</tr>)
		payment_rows.push(<tr>
			<td>Auth Code</td>
			<td>{p.auth_code}</td>
		</tr>)

		paymentSections.push(<table>
			<tbody>
				<tr>
					<td className='title_row' colspan='2'>Payment</td>
				</tr>
				{payment_rows}
			</tbody>
		</table>)
	}


	tbl_view_payment = paymentSections;

	// tbl_view_payment = <table>
	// 	<tbody>
	// 		<tr>
	// 			<td className='title_row' colspan='2'>Payment(s)</td>
	// 		</tr>
	// 		{payment_rows}
	// 	</tbody>
	// </table>
	

	let selected_outbound = null;
	let selected_inbound = null;
	let selected_room = null;
	let flight_costings = [];
	let flight_inbound_costings = [];
	let flight_extras = [];
	let flight_extras_inbound = [];

	let failed_coms = [];

	if(booking_room_d != null && accommodation_d != null){
		tbl_view_acc = room_prod_table(booking_room_d, accommodation_d);

		for(const brd of booking_room_d){
			if(brd.booking_component == null){
				failed_coms.push(<li>Room(s)</li>);
				break
			}
			else if(!brd.booking_component.success){
				failed_coms.push(<li>Room(s)</li>);
				break
			}
		}

	}
	if(booking_flight_d != null){
		tbl_view_flights = flight_prod_table(booking_flight_d);
		if(booking_flight_d.booking_component == null){
			failed_coms.push(<li>Flights</li>)
		}		
		else if(!booking_flight_d.booking_component.success){
			failed_coms.push(<li>Flights</li>)
		}
	}

	if(booking_transfers_d != null){
		tbl_view_transfer = transfer_prod_table(booking_transfers_d);
		if(booking_transfers_d.booking_component == null){
			failed_coms.push(<li>Transfers</li>)
		}
		else if(!booking_transfers_d.booking_component.success){
			failed_coms.push(<li>Transfers</li>)
		}
	}

	if(booking_flight_extras_d != null){
		tbl_view_flight_extra = flight_extra_prod_table(booking_flight_extras_d);
	}

	if(booking_package_d != null){
		tbl_view_package = package_prod_table(booking_package_d);
		if(booking_package_d.booking_component == null){
			failed_coms.push(<li>Package</li>)
		}		
		else if(!booking_package_d.booking_component.success){
			failed_coms.push(<li>Package</li>)
		}
	}

	let bnotes = []
	for(let bn of booking_notes){

		let date_of_note = dayjs(bn.created, 'YYYY-MM-DD HH:mm');
		date_of_note = date_of_note.format('DD/MM/YY HH:mm');

		let note_r = bn.note.replaceAll('\n', '~')
		let para_txt = note_r.split('~')
		let para_collectionm = []
		for(let p of para_txt){
			para_collectionm.push(<p>{p}</p>)
		}

		bnotes.push(
		<div className='note'>
			<div className='note_timestamp'>
				{bn.user_name} @ {date_of_note}
			</div>
			<div className='note_body'>
				{para_collectionm}
			</div>
		</div>)
	}
	


	let status_msg = []
	let book_status_btn = [];
	let uli_tk = getCookie('usign',true);
	let role_id = uli_tk["role_id"];

	let booking_logs_btn = [];

	if(!processing){
		book_status_btn.push(<button id='btn_submit' onClick={() => add_note()}>Add Note</button>);
		if(booking_d.confirmed == 1){
			status_msg.push(<div className='status_msg_con'>Confirmed</div>)
			book_status_btn.push(<button id='btn_status_c' onClick={() => set_booking_conf(false)}>Action Required</button>);
		}else{
			book_status_btn.push(<button id='btn_status_c' onClick={() => set_booking_conf(true)}>Confirm</button>);
			if(failed_coms.length > 0){
				status_msg.push(<div className='status_msg_con fail'>Failed components <ul>{failed_coms}</ul></div>)
			}
		}

		if(role_id < 3){
			if(booking_d.hidden){
				book_status_btn.push(<button id='btn_status_c' onClick={() => set_booking_hide(false)}>Unhide</button>);
			}
			else{
				book_status_btn.push(<button id='btn_status_c' onClick={() => set_booking_hide(true)}>Hide</button>);
			}

			booking_logs_btn = 	<div className='section' style={{marginTop:'30px'}}><button onClick={() => get_logs()}>Download Booking Logs</button></div>
		}
	
	}
	else{
		book_status_btn.push(<div class="loader"></div>)
	}



	let add_note_section = null;
	if(booking_d.status == 0){
		//booking is closed
		add_note_section = <div className='notes_form'>
		<div>
			<textarea onChange={(e) => setNote(e.target.value)} value={note} type='t' cols='70' rows='20'></textarea>
		</div>
		<div>
			{book_status_btn}
		</div>
		</div>
	}



	let acc_section = null;
	if(tbl_view_acc != null){
		acc_section = <div className='flight_tbl tbl_sec'>{tbl_view_acc}</div>
	}

	let flight_section = null;
	if(tbl_view_flights != null){
		flight_section = <div className='flight_tbl tbl_sec'>{tbl_view_flights}</div>
	}

	let flight_extra_section = null;
	if(tbl_view_flight_extra != null){
		flight_extra_section = <div className='flight_tbl tbl_sec'>{tbl_view_flight_extra}</div>
	}

	let transfer_section = null;
	if(tbl_view_transfer != null){
		transfer_section = <div className='flight_tbl tbl_sec'>{tbl_view_transfer}</div>
	}

	let package_section = null;
	if(tbl_view_package){
		package_section = <div className='package_tbl tbl_sec'>{tbl_view_package}</div>
	}

	let info_section = null;
	if(booking_d.important_information != ''){
		info_section = <div className='info_section'><div className='innersection'><h1>Important Information</h1><p>{booking_d.important_information}</p></div></div>
	}

	let tbl_view_payment_plan = null;
	if(booking_payment_plan_d != null){
		let payment_plan_rows = []
		let payment_plan_instal_rows = [];

		payment_plan_rows.push(<tr>
			<td>Outstanding balance</td>
			<td>{booking_payment_plan_d.outstanding_balance}</td>
			</tr>)
		payment_plan_rows.push(<tr>
			<td>Payment Day</td>
			<td>{booking_payment_plan_d.payment_day}</td>
			</tr>)

		payment_plan_instal_rows.push(<tr>
				<td>Payment Date</td>
				<td>Amount</td>

		</tr>)

		for(const p of booking_payment_plan_d.instalments){
			let date_due = dayjs(p.date_due, 'YYYY-MM-DD HH:mm');
			date_due = date_due.format('DD/MM/YY');

			
			payment_plan_instal_rows.push(<tr>
				<td>{date_due}</td>
				<td>{p.amount}</td>

			</tr>)
		}

		tbl_view_payment_plan = <div className='tbl_sec'><table>
		<tbody>
			<tr>
				<td className='title_row' colspan='2'>Payment Plan</td>
			</tr>
			{payment_plan_rows}
		</tbody>

		<tbody>
			<tr>
				<td className='title_row' colspan='2'>Instalments</td>
			</tr>
			{payment_plan_instal_rows}
		</tbody>

		</table></div>

	}

	return (
		<section className='aboutcontent-box'>
	
			<div className='container'>

		
		
		

				<div className='booking_tbl tbl_sec'>

		
					{status_msg}

					{booking_logs_btn}

					{tbl_view_booking}
			
				</div>

				<div className='payment_tbl tbl_sec'>
					{tbl_view_payment}
				</div>

			
				{tbl_view_payment_plan}


			

			
		

				{acc_section}
				{flight_section}
				{flight_extra_section}
				{transfer_section}
				{package_section}
				{info_section}

				{/* <div className='flight_tbl tbl_sec'>
					{tbl_view_flights}
				</div>

				<div className='transfer_tbl tbl_sec'>
					{tbl_view_transfer}
				</div>

				<div className='acc_tbl tbl_sec'>
					{tbl_view_acc}
				</div>

				<div className='cost_tbl tbl_sec'>
					{tbl_total_cost}
				</div> */}

				<div className='note_section'>
					{bnotes}
				</div>
				
				{add_note_section}
		
		
		
			
			


				


			</div>
		</section>
	);
};

export default BookingView;
