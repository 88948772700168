import React, { useState, useEffect,  } from "react";
import dayjs from 'dayjs'

const YMDPicker = ({
  onChange,
  validate = null,
  initalValue = null,
  future_years = 0,
  enable_time=false,
  prev_years = 5,
  show_day_selector=true,
}) => {

  let m = dayjs()


  let ini_dob = dayjs(initalValue, "YYYY-MM-DD");


  const [selected_day, update_selected_day] = useState(null);
  const [selected_month, update_selected_month] = useState(null);
  const [selected_year, update_selected_year] = useState(null,);

  const [selected_hour, update_selected_hour] = useState("00");
  const [selected_min, update_selected_min] = useState("00");

  let days = [];
  let _months = [1,2,3,4,5,6,7,8,9,10,11,12]
  let months = [];
  let years = [];

  let hours = [];
  let mins = [];

  useEffect(() => {
    if(initalValue != null){
        let ini_dob = dayjs(initalValue, "YYYY-MM-DD");
        update_selected_day(ini_dob.date());
        update_selected_year(ini_dob.year());
        update_selected_month(ini_dob.month() + 1);
      }
  }, []);

  useEffect(() => {
    if(initalValue == null){
        //reset this control
        update_selected_day(null);
        update_selected_year(null);
        update_selected_month(null);
    }
    else{
      let ini_dob = dayjs(initalValue,"YYYY-MM-DD");
      update_selected_day(ini_dob.date());
      update_selected_year(ini_dob.year());
      update_selected_month(ini_dob.month() + 1);
    }
  }, [initalValue]);



  // useEffect(() =>{
  //   update_selection();
  // },[selected_day, selected_month, selected_year, selected_hour, selected_min]);


  let sel_dob = dayjs(selected_year + "/" + selected_month + "/" + 1,"YYYY-MM-DD");

  days.push(<option>-</option>);

  let max_days = sel_dob.daysInMonth();

  if(selected_day <= max_days){
    sel_dob = dayjs(selected_year + "/" + selected_month + "/" + selected_day,"YYYY-MM-DD");
  }
  else{
    sel_dob = dayjs(selected_year + "/" + selected_month + "/" + max_days,"YYYY-MM-DD");
  }

  if(selected_month == null && selected_year == null){
      max_days = 31;
  }
  for(let i =1; i <= max_days; i++){
    if(i == sel_dob.date()){
        days.push(<option selected='true'>{i}</option>);
    }
    else{
        days.push(<option>{i}</option>);
    }
  }

  months.push(<option>-</option>)
  for(let i =1; i <= _months.length; i++){
    let m = dayjs().set('month', (i -1))
    if(i == (sel_dob.month() + 1)){
        months.push(<option selected='true' value={i}>{m.format("MMM")}</option>);
    }
    else{
        months.push(<option value={i}>{m.format("MMM")}</option>);
    }
  }


    let todays_year = dayjs().year();

    years.push(<option>-</option>)

    for(let i = todays_year - prev_years; i <= todays_year + future_years; i++){
        if(i == sel_dob.year()){
            years.push(<option selected='true'>{i}</option>);
        }
        else{
            years.push(<option>{i}</option>);
        }
    }
  

    for(let i =0 ; i < 24; i++){

      let stri = i.toString();
      if(i < 10){
        stri = '0' + i.toString();
      }
      hours.push(<option>{stri}</option>);
    }

    for(let i =0; i < 60; i++){

      let stri = i.toString();
      if(i < 10){
        stri = '0' + i.toString();
      }
      mins.push(<option>{stri}</option>);
    }


  const select_day = (value) => {
    update_selected_day(value);
    if(selected_month == null){
        update_selected_month(1);
    }
    if(selected_year == null){
        update_selected_year(todays_year);
    }

    update_selection(selected_year, selected_month, value, selected_hour, selected_min);
  }
  const select_month = (value) => {
    update_selected_month(value);
    if(selected_day == null){
        update_selected_day(1);
    }    
    if(selected_year == null){
        update_selected_year(todays_year);
    }

    update_selection(selected_year, value, selected_day, selected_hour, selected_min);

  }
  const select_year = (value) => {
    update_selected_year(value);
    if(selected_month == null){
        update_selected_month(1);
    }
    if(selected_day == null){
        update_selected_day(1);
    }

    update_selection(value, selected_month, selected_day, selected_hour, selected_min);
  }


  const select_hour = (value) => {
    update_selected_hour(value);
    update_selection(selected_year, selected_month, selected_day, value, selected_min);
  }

  const select_mins = (value) => {
    update_selected_min(value);
    update_selection(selected_year, selected_month, selected_day, selected_hour, value);
  }

  const update_selection = (year, month ,day, hour= null, min=null) =>{


    if(year == '-' || month == '-' || day == '-') {
        onChange(null);
        return;
    }
    if(year != null && month != null & day != null){
        if(enable_time){
          onChange(year + "-" + month + "-" + day + " " + hour + ":" + min);
          return
        }
        onChange(year + "-" + month + "-" + day);
    }
    else{
        onChange(null)
    }
    

   }

   let time_section = null;
   if(enable_time){

    time_section = <><select
    className=""
    onChange={(e) => select_hour(e.target.value)}
  >
    {hours}
  </select>

  <select
    className=""
    onChange={(e) => select_mins(e.target.value)}
  >
    {mins}
  </select></>
   }

   let daySelector = null;
   if(show_day_selector){
    daySelector =   <select
    className=""
    onChange={(e) => select_day(e.target.value)}
  >
    {days}
  </select>
   }

  return(
    <div className="field" style={{position:'relative'}}>
    <div className="control">
      <div
        className={"date_select"}
      >
      
      {daySelector}
        <select
          className=""
          onChange={(e) => select_month(e.target.value)}
        >
          {months}
        </select>

        <select
          className=""
          onChange={(e) => select_year(e.target.value)}
        >
          {years}
        </select>

        {time_section}

      </div>
    </div>
  </div>
  );

}

export default YMDPicker;
