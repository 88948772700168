import React, {useEffect, useState} from 'react';

import AccommodationHits from '../../components/charts/acc/acc_hits'
import RegionCountMonth from '../../components/charts/destination/region_count_month'
import DestinationCountDeparturePoint from '../../components/charts/destination/destination_count_deppoint'
import Progress from '../../components/charts/journey/progress'
import BookingCountDate from '../../components/charts/booking/booking_count_date'
import BookingCostDate from '../../components/charts/booking/booking_avg_cost'
import BookingCostProdDate from '../../components/charts/booking/booking_avg_cost_prod_date'
import BookingCountProdDate from '../../components/charts/booking/booking_count_prod_date'



const Stats = ({user_token, set_menu, authorize}) => {

	useEffect(() =>{
		
		authorize(5);
		const lnks = [{'url': '/', 'name': 'Home'}, {'url': '/stats/', 'name': 'Stats'}, {'url': '/stats/acc', 'name': 'Accommodation'}, {'url': '/stats/destination', 'name': 'Destination'}, {'url': '/stats/journey', 'name': 'Journey'}, {'url': '/stats/booking', 'name': 'Bookings'}]
		set_menu(lnks)



    }, [])

	return (
        <div style={{display:'flex', flexWrap:'wrap', margin:'50px 50px 50px 50px'}}>

			<div>
				<div style={{textAlign:'center'}}>Accommodation hits share</div>
				<AccommodationHits token={user_token}></AccommodationHits>
			</div>
		
			<div>
				<div style={{textAlign:'center'}}>Destination by departure</div>
				<DestinationCountDeparturePoint token={user_token}></DestinationCountDeparturePoint>	
			</div>
		
			<div>
				<div style={{textAlign:'center'}}>Region by month</div>
			<RegionCountMonth token={user_token}></RegionCountMonth>
			</div>

			<div>
				<div style={{textAlign:'center'}}>Journey progress</div>
			<Progress token={user_token}></Progress>
			</div>
			
			<div>
				<div style={{textAlign:'center'}}>Booking average cost</div>
			<BookingCostProdDate token={user_token}></BookingCostProdDate>
			</div>
        </div>
	);
};

export default Stats;
